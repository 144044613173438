import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Chip,
  Divider,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Modal,
  Box,
  createFilterOptions,
  Button,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import CButton from "../../../utils/components/Button";

const axios = require("axios");
const filter = createFilterOptions();

const extraPrecisionSplitter = " + ";
export const UNCERTAINTY_LINK = "__";

export function EditTable(props) {
  const [rangeCol, setRangeCol] = useState("");
  const [datasheetColumnName, setDatasheetColumnName] = useState([]);
  const [datasheetConfiguration, setDatasheetConfiguration] = useState({
    values: {},
    formulas: {},
    totalColumns: 0,
    hasCommonHeader: false,
    commonHeaderConfig: null,
    budget: {
      formulas: {},
    },
  });
  const [certificateColumnName, setCertificateColumnName] = useState([]);
  const [certificateConfiguration, setCertificateConfiguration] = useState({
    relations: {},
    formulas: {},
    totalColumns: 0,
    hasCommonHeader: false,
    commonHeaderConfig: null,
  });
  const [condConfiguration, setCondConfiguration] = useState({
    relations: {},
  });
  const [customPrecision, setCustomPrecision] = useState({});
  const [typeBConfiguration, setTypeBConfiguration] = useState({
    relations: {},
  });

  const [selectedBudgetFormula, setSelectedBudgetFormula] = useState(null);
  const [selectedValueCol, setSelectedValueCol] = useState(null);
  const [selectedFormulaCol, setSelectedFormulaCol] = useState(null);
  const [selectedCondition, setSelectedCondition] = useState("default");
  const [selectedFormulaCol1, setSelectedFormulaCol1] = useState(null);
  const [dsCol, setDsCol] = useState(null);
  const [dsCol2, setDsCol2] = useState(null);
  const [plusPrecision, setPlusPrecision] = useState("");
  const [stdRangePref, setStdRangePref] = useState("null");
  const [conditionsCol, setConditionsCol] = useState(null);

  const [selectedCertFormulaCol, setSelectedCertFormulaCol] = useState(null);
  const [selectedDatasheetRelCol, setSelectedDatasheetRelCol] = useState(null);
  const [selectedCertRelCol, setSelectedCertRelCol] = useState(null);
  const [selectedTypeBRelCol, setSelectedTypeBRelCol] = useState(null);

  var [instrumentArray, setInstrumentArray] = useState([]);
  var [uncertaintyFactors, setUncertaintyFactors] = useState([]);
  const [unitType, setUnitType] = useState("");
  const [parameterName, setParameterName] = useState("");
  const [isUncertainty, setUncertainty] = useState(false);
  const [isVerticalUncertainty, setIsVerticalUncertainty] = useState(false);
  const [isCmc, setIsCmc] = useState(false);
  const [sketchPickerColor, setSketchPickerColor] = useState("#D12929");
  const [certificateTableStatus, setCertificateTableStatus] = useState(1);

  const [tableName, setTableName] = useState("");
  const [instrumentId, setInstrumentId] = useState(null);
  const [instrumentName, setInstrumentName] = useState(null);
  const [defaultConfiguration, setDefaultConfiguration] = useState("");
  const [showHeader, setShowHeader] = useState(true);

  // destructuring rgba from state
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const params = useParams();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const onSubmit = () => {
    var jsonData = {
      tableName: tableName,
      unitType: unitType,
      instrumentId: instrumentId,
      parameterName: parameterName,
      defaultConfiguration: JSON.stringify({
        ...datasheetConfiguration,
        typeb: typeBConfiguration,
        rangeCol: rangeCol,
      }),
      conditionFormatting: JSON.stringify({
        ...condConfiguration,
      }),
      customPrecision: JSON.stringify({
        ...customPrecision,
      }),
      isUncertainty: isUncertainty,
      isVerticalUncertainty: isVerticalUncertainty,
    };
    for (let i = 1; i < 31; i++) jsonData["c" + i] = null;
    datasheetColumnName.forEach((column, index) => {
      jsonData["c" + (index + 1)] = column;
    });

    let url = BASE_URL;
    if (params.id > 0) {
      axios
        .patch(url + `datasheetStaticTables/${params.id}`, jsonData)
        .then((res) => {
          submitCertificate();
        })
        .catch((error) => {
          console.log(error);
          toast.error("Something went wrong!");
        });
    } else {
      axios
        .post(url + "datasheetStaticTables", jsonData)
        .then((res) => {
          submitCertificate();
        })
        .catch((error) => {
          console.log(error);
          toast.error("Something Went Wrong3!");
        });
    }
  };

  const submitCertificate = () => {
    var jsonData = {
      tableName: tableName,
      unitType: unitType,
      instrumentId: instrumentId,
      defaultConfiguration: JSON.stringify({
        ...certificateConfiguration,
        showHeader,
      }),
      isUncertainty: isUncertainty,
      isVerticalUncertainty: isVerticalUncertainty,
      isCmc: isCmc,
      status: certificateTableStatus,
    };
    for (let i = 1; i < 31; i++) jsonData["c" + i] = null;
    certificateColumnName.forEach((column, index) => {
      jsonData["c" + (index + 1)] = column;
    });

    if (params.id > 0) {
      axios
        .patch(BASE_URL + `certificateStaticTables/${params.id}`, jsonData)
        .then((res) => {
          // props.setLoader(false);
          toast("Table updated!");
        })
        .catch((error) => {
          // props.setLoader(false);
          toast.error("Something Went Wrong with certificate table!");
        });
    } else {
      axios
        .post(BASE_URL + `certificateStaticTables`, jsonData)
        .then((res) => {
          // props.setLoader(false);
          toast("new table added!");
        })
        .catch((error) => {
          // props.setLoader(false);
          toast.error("Something Went Wrong in certificate!");
        });
    }
  };

  const addDatasheetColumn = () => {
    setDatasheetColumnName([...datasheetColumnName, ""]);
    updateDatasheetConfiguration(
      "totalColumns",
      null,
      Number(datasheetConfiguration["totalColumns"]) + 1
    );
  };

  const deleteDatasheetColumn = (index) => {
    let newDatasheetColumnName = [...datasheetColumnName];
    newDatasheetColumnName.splice(index, 1);
    setDatasheetColumnName([...newDatasheetColumnName]);
  };
  const updateDatasheetColumnName = (value, index) => {
    const newColumnName = [...datasheetColumnName];
    newColumnName[index] = value;
    setDatasheetColumnName(newColumnName);
  };

  const updateDatasheetConfiguration = (type, column, value) => {
    let _datasheetConfiguration = { ...datasheetConfiguration };
    if (type === "formulas") {
      if (
        Object.keys(_datasheetConfiguration[type][column] || {}).length == 0
      ) {
        _datasheetConfiguration[type][column] = {};
      }
      _datasheetConfiguration[type][column][selectedCondition || "default"] =
        value;
    } else if (type === "budget.formulas") {
      if (Object.keys(_datasheetConfiguration["budget"] || {}).length == 0) {
        _datasheetConfiguration["budget"] = {
          formulas: {},
        };
      }
      if (
        Object.keys(_datasheetConfiguration["budget"]["formulas"][column] || {})
          .length == 0
      ) {
        _datasheetConfiguration["budget"]["formulas"][column] = {};
      }
      _datasheetConfiguration["budget"]["formulas"][column][
        selectedCondition || "default"
      ] = value;
    } else if (column != null) {
      _datasheetConfiguration[type][column] = value;
    } else {
      _datasheetConfiguration[type] = value;
    }

    setDatasheetConfiguration(_datasheetConfiguration);
  };

  const updateCertificateConfiguration = (
    type,
    column = null,
    value = null
  ) => {
    let _certificateConfiguration = { ...certificateConfiguration };
    if (column != null) {
      _certificateConfiguration[type][column] = value;
    } else if (
      type === "relations" &&
      selectedCertRelCol &&
      selectedDatasheetRelCol
    ) {
      _certificateConfiguration[type][selectedCertRelCol] =
        selectedDatasheetRelCol;
    } else {
      _certificateConfiguration[type] = value;
    }
    setCertificateConfiguration(_certificateConfiguration);
  };

  const condConfObj = {
    gt: "Greater than",
    lt: "Less than",
    between: "Between",
    precisionCountNotEqual: "Precision not equals",
    "Greater than": 1,
    "Less than": 2,
    Between: 3,
    "Precision not equals": 4,
    1: "gt",
    2: "lt",
    3: "between",
    4: "precisionCountNotEqual",
  };

  const updateConditionConfiguration = (type, column = null, value = null) => {
    let _conditionsConfiguration = { ...condConfiguration };
    if (column != null) {
      _conditionsConfiguration[type][column] = value;
    } else if (type === "relations" && conditionsCol && selectedFormulaCol1) {
      // Greater than", "Less than","Between
      let condition = "";
      // switch (conditionsCol) {
      //   case 1:
      //     condition = "gt";
      //     break;
      //   case 2:
      //     condition = "lt";
      //     break;
      //   case 3:
      //     condition = "between";
      //     break;
      //   case 4:
      //     condition = "precisionCountNotEqual";
      //     break;
      //   default:
      //     condition = "";
      // }
      condition = condConfObj[conditionsCol];
      _conditionsConfiguration[type][selectedFormulaCol1] = {
        condition: condition,
        dsCol: dsCol + "." + stdRangePref,
        color: sketchPickerColor,
      };
    } else {
      _conditionsConfiguration[type] = value;
    }
    setCondConfiguration(_conditionsConfiguration);
  };

  const updateCustomPrecision = (column = null, value = null) => {
    let _customPrecision = { ...customPrecision };
    _customPrecision[selectedFormulaCol1] =
      dsCol2 + extraPrecisionSplitter + plusPrecision + "." + stdRangePref;
    setCustomPrecision(_customPrecision);
  };

  const updateTypeBConfiguration = (type, column = null, value = null) => {
    let _typeBConfiguration = { ...typeBConfiguration };
    if (
      type === "relations" &&
      selectedTypeBRelCol &&
      selectedDatasheetRelCol
    ) {
      if (!_typeBConfiguration[type]) {
        _typeBConfiguration[type] = {};
      }

      _typeBConfiguration[type][selectedDatasheetRelCol] = selectedTypeBRelCol;
    }
    setTypeBConfiguration(_typeBConfiguration);
  };

  const addCertificateColumn = () => {
    setCertificateColumnName([...certificateColumnName, ""]);
    updateCertificateConfiguration(
      "totalColumns",
      null,
      Number(certificateConfiguration["totalColumns"]) + 1
    );
  };

  const deleteCertificateColumn = (index) => {
    let newCertificateColumnName = [...certificateColumnName];
    newCertificateColumnName.splice(index, 1);
    setCertificateColumnName([...newCertificateColumnName]);
  };

  const updateCertificateColumnName = (value, index) => {
    const newColumnName = [...certificateColumnName];
    newColumnName[index] = value;
    setCertificateColumnName(newColumnName);
  };

  const getDatasheet = () => {
    let url = BASE_URL;
    axios
      .get(url + `datasheetStaticTables/${params.id}`)
      .then((res) => {
        setUnitType(res.data[0].unitType);
        setTableName(res.data[0].tableName);
        setParameterName(res.data[0].parameterName);
        setUncertainty(res.data[0].isUncertainty);
        setDatasheetConfiguration(
          res.data[0].defaultConfiguration != null
            ? JSON.parse(res.data[0].defaultConfiguration)
            : {}
        );
        setTypeBConfiguration(
          res.data[0].defaultConfiguration != null
            ? JSON.parse(res.data[0].defaultConfiguration).typeb
              ? JSON.parse(res.data[0].defaultConfiguration).typeb
              : {}
            : {}
        );
        setCondConfiguration(
          res.data[0].conditionFormatting != null
            ? JSON.parse(res.data[0].conditionFormatting)
            : {}
        );
        setCustomPrecision(
          res.data[0].customPrecision != null
            ? JSON.parse(res.data[0].customPrecision)
            : {}
        );
        const columnArr = [];
        columnArr.push(
          res.data[0].c1,
          res.data[0].c2,
          res.data[0].c3,
          res.data[0].c4,
          res.data[0].c5,
          res.data[0].c6,
          res.data[0].c7,
          res.data[0].c8,
          res.data[0].c9,
          res.data[0].c10,
          res.data[0].c11,
          res.data[0].c12,
          res.data[0].c13,
          res.data[0].c14,
          res.data[0].c15,
          res.data[0].c16,
          res.data[0].c17,
          res.data[0].c18,
          res.data[0].c19,
          res.data[0].c20,
          res.data[0].c21,
          res.data[0].c22,
          res.data[0].c23,
          res.data[0].c24,
          res.data[0].c25,
          res.data[0].c26,
          res.data[0].c27,
          res.data[0].c28,
          res.data[0].c29,
          res.data[0].c30
        );
        setDatasheetColumnName(
          columnArr.filter(function (el) {
            return el != null;
          })
        );
        let rangeCol = JSON.parse(res.data[0].defaultConfiguration).rangeCol;
        setRangeCol(rangeCol);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const getCertificate = () => {
    let url = BASE_URL;
    axios
      .get(
        url +
          `xjoin?_join=cert.certificateStaticTables,_j,inst.instruments&_on1=(cert.instrumentId,eq,inst.id)&_fields=cert.status,cert.instrumentId,cert.isUncertainty,cert.isVerticalUncertainty,cert.isCmc,cert.defaultConfiguration,cert.c1,cert.c2,cert.c3,cert.c4,cert.c5,cert.c6,cert.c7,cert.c8,cert.c9,cert.c10,cert.c11,cert.c12,cert.c13,cert.c14,cert.c15,cert.c16,cert.c17,cert.c18,cert.c19,cert.c20,cert.c21,cert.c22,cert.c23,cert.c24,cert.c25,cert.c26,cert.c27,cert.c28,cert.c29,cert.c30,inst.instrumentName&_where=(cert.id,eq,${params.id})`
      )
      // /api/
      .then((res) => {
        setCertificateTableStatus(res.data[0].cert_status);
        setInstrumentId(res.data[0].cert_instrumentId);
        setInstrumentName(res.data[0].inst_instrumentName);
        setUncertainty(res.data[0].cert_isUncertainty);
        setIsVerticalUncertainty(res.data[0].cert_isVerticalUncertainty);
        setIsCmc(res.data[0].cert_isCmc);
        setCertificateConfiguration(
          res.data[0].cert_defaultConfiguration != null
            ? JSON.parse(res.data[0].cert_defaultConfiguration)
            : {}
        );
        setShowHeader(
          res.data[0].cert_defaultConfiguration != null
            ? JSON.parse(res.data[0].cert_defaultConfiguration)?.showHeader
            : true
        );
        setDefaultConfiguration(res.data[0].cert_defaultConfiguration);
        const columnArr = [];
        columnArr.push(
          res.data[0].cert_c1,
          res.data[0].cert_c2,
          res.data[0].cert_c3,
          res.data[0].cert_c4,
          res.data[0].cert_c5,
          res.data[0].cert_c6,
          res.data[0].cert_c7,
          res.data[0].cert_c8,
          res.data[0].cert_c9,
          res.data[0].cert_c10,
          res.data[0].cert_c11,
          res.data[0].cert_c12,
          res.data[0].cert_c13,
          res.data[0].cert_c14,
          res.data[0].cert_c15,
          res.data[0].cert_c16,
          res.data[0].cert_c17,
          res.data[0].cert_c18,
          res.data[0].cert_c19,
          res.data[0].cert_c20,
          res.data[0].cert_c21,
          res.data[0].cert_c22,
          res.data[0].cert_c23,
          res.data[0].cert_c24,
          res.data[0].cert_c25,
          res.data[0].cert_c26,
          res.data[0].cert_c27,
          res.data[0].cert_c28,
          res.data[0].cert_c29,
          res.data[0].cert_c30
        );
        setCertificateColumnName(
          columnArr.filter(function (el) {
            return el != null;
          })
        );
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const getInstrumentsList = () => {
    let url = BASE_URL;
    axios
      .get(url + "instruments?_where=(status,eq,1)")
      .then((res) => {
        setInstrumentArray(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const fetchUncertaintyFactors = () => {
    let uncertaintyFactors = instrumentArray.filter(
      (instrument) => instrument.id == instrumentId
    )[0]?.uncertaintyFactors;
    if (!uncertaintyFactors) return;
    axios
      .get(BASE_URL + `uncertainty?_where=(id,in,${uncertaintyFactors})`)
      .then((res) => {
        setUncertaintyFactors([...res.data]);
      })
      .catch((error) => {
        toast.error("Failed to uncertainty factors!");
      });
  };

  const setMappedRange = (val) => {
    console.log("==>>", val);
    setRangeCol(val);
  };

  useEffect(() => {
    getInstrumentsList();
    if (params.id > 0) {
      getDatasheet();
      getCertificate();
    }
  }, []);

  useEffect(() => {
    // if (params.id > 0) {
    fetchUncertaintyFactors();
    // }
  }, [instrumentArray, instrumentId]);

  const renderHelpModal = () => {
    return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Formula Help
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            same as mathematical expression with following variations
            <hr />
            <ul>
              <li>
                1. multiplication: <code>*</code>
              </li>
              <li>
                2. for power:{" "}
                <code>pow(&lt;base value&gt;, &lt;exponent&gt;)</code>
              </li>
              <li>
                3. for square root : <code>sqrt(&lt;value&gt;)</code>, value:
                either number of column code (eg. c1)
              </li>
            </ul>
          </Typography>
        </Box>
      </Modal>
    );
  };

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" sx={{ mb: 2 }}>
        {params.id > 0 ? "Update" : "Create new"} static table
      </Typography>
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={4}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            value={instrumentId + ", " + instrumentName}
            options={instrumentArray.map((instrument) => {
              return {
                label: instrument.id + ", " + instrument.instrumentName,
              };
            })}
            onChange={(event, value) => {
              if (value) {
                setInstrumentId(parseInt(value.label.split(",")[0]));
                setInstrumentName(value.label.split(",")[1]);
                // fetchUncertaintyFactors(value.label.split(",")[0]);
              }
            }}
            renderInput={(params) => (
              <TextField {...params} label="Instrument" />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="outlined-basic"
            label="New table name"
            size="small"
            fullWidth
            variant="outlined"
            value={tableName}
            onChange={(e) => setTableName(e.target.value)}
          />
        </Grid>
      </Grid>

      {/* datasheet table confiurations */}
      <div>
        <Divider sx={{ mt: 5 }}>
          <Chip
            label="Datasheet table configuration"
            variant="outlined"
            sx={{ fontSize: "16px" }}
          />
        </Divider>

        <div sx={{ mb: 3 }}>
          <Typography variant="h6" component="h6" style={{ float: "left" }}>
            1. add columns
            <CButton
              variant="contained"
              size="small"
              label={<AddIcon />}
              sx={{ ml: 3 }}
              onClick={() => {
                addDatasheetColumn();
              }}
            ></CButton>
            {`    use "${UNCERTAINTY_LINK}" to show uncertainty link e.g. col__typeA1`}
          </Typography>
          <Grid container spacing={1}>
            {datasheetColumnName.map((column, index) => (
              <Grid item xs={4}>
                <OutlinedInput
                  id="outlined-adornment-password"
                  label={"column " + (index + 1) + "(c" + (index + 1) + ")"}
                  value={column}
                  placeholder={
                    "column " + (index + 1) + " (c" + +(index + 1) + ")"
                  }
                  onChange={(e) => {
                    updateDatasheetColumnName(e.target.value, index);
                  }}
                  size="small"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          deleteDatasheetColumn(index);
                        }}
                        edge="end"
                      >
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>
            ))}
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <FormControlLabel
                control={<Checkbox />}
                label="Show Column Header in Certificate"
                checked={showHeader}
                onChange={(e) => {
                  setShowHeader(e.target.checked);
                }}
              />
            </Grid>
          </Grid>
        </div>
        <br />
        <div>
          <Typography variant="h6" component="h6" style={{ float: "left" }}>
            2. Define common header{" "}
            <CButton
              variant="text"
              size="small"
              label={<HelpCenterIcon />}
              onClick={handleOpen}
            ></CButton>
          </Typography>
          <Grid container spacing={1} sx={{ mb: 3 }}>
            <Grid item xs={3}>
              <FormControlLabel
                control={<Checkbox />}
                label="Has common header"
                checked={datasheetConfiguration?.hasCommonHeader}
                onChange={(e) => {
                  updateDatasheetConfiguration(
                    "hasCommonHeader",
                    null,
                    e.target.value
                  );
                }}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="outlined-basic"
                label="Define common header"
                placeholder="Define common header (, for column separation, $: for empty column)"
                size="small"
                fullWidth
                variant="outlined"
                value={datasheetConfiguration?.commonHeaderConfig || ""}
                onChange={(e) =>
                  updateDatasheetConfiguration(
                    "commonHeaderConfig",
                    null,
                    e.target.value
                  )
                }
              />
            </Grid>
          </Grid>
        </div>
        <br />
        <Typography variant="h6" component="h6" style={{ float: "left" }}>
          3. Set default values (optional)
        </Typography>
        <Grid container spacing={1} sx={{ mb: 3 }}>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select Column
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedValueCol}
                label="Select column"
                onChange={(e) => {
                  setSelectedValueCol(e.target.value);
                }}
                size="small"
              >
                {datasheetColumnName.map((columnName, index) => {
                  return (
                    <MenuItem value={"c" + (index + 1)}>{columnName}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={9}>
            <TextField
              id="outlined-basic"
              label="Add values ( comma separated )"
              size="small"
              fullWidth
              variant="outlined"
              value={datasheetConfiguration?.values[selectedValueCol] || ""}
              onChange={(e) =>
                updateDatasheetConfiguration(
                  "values",
                  selectedValueCol,
                  e.target.value
                )
              }
            />
          </Grid>
        </Grid>
        <Typography variant="h6" component="h6" style={{ float: "left" }}>
          4. Map Column to Range
        </Typography>
        <Grid container spacing={1} sx={{ mb: 3 }}>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select Column
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rangeCol}
                label="Select column"
                onChange={(e) => {
                  setMappedRange(e.target.value);
                }}
                size="small"
              >
                {datasheetColumnName.map((columnName, index) => {
                  return (
                    <MenuItem value={"c" + (index + 1)}>{columnName}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Typography variant="h6" component="h6" style={{ float: "left" }}>
          5. Set formula (optional){" "}
          <CButton
            variant="text"
            size="small"
            label={<HelpCenterIcon />}
            onClick={handleOpen}
          ></CButton>
        </Typography>
        <Grid container spacing={1} sx={{ mb: 3 }}>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select column
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedFormulaCol}
                label="Select column"
                onChange={(e) => {
                  setSelectedFormulaCol(e.target.value);
                }}
                size="small"
              >
                {datasheetColumnName.map((columnName, index) => {
                  return (
                    <MenuItem value={"c" + (index + 1)}>{columnName}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              value={selectedCondition}
              onChange={(event, newValue) => {
                if (typeof newValue === "string") {
                  setSelectedCondition(newValue);
                } else if (newValue && newValue.inputValue) {
                  setSelectedCondition(newValue.inputValue);
                } else {
                  setSelectedCondition(newValue);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some(
                  (option) => inputValue === option
                );
                if (inputValue !== "" && !isExisting) {
                  filtered.push(inputValue);
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="free-solo-with-text-demo"
              options={Object.keys(
                datasheetConfiguration?.formulas[selectedFormulaCol] || {
                  default: "",
                }
              )}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
              }}
              renderOption={(props, option) => (
                <li {...props} style={{ display: "flex" }}>
                  <div style={{ flex: "1" }}>{option}</div>
                  <button
                    style={{ color: "#888" }}
                    onClick={(e) => {
                      let tmpDt = { ...datasheetConfiguration };
                      let tmp = tmpDt?.formulas[selectedFormulaCol];
                      delete tmp[props["key"]];
                      if (Object.keys(tmp).length === 0)
                        delete tmpDt.formulas[selectedFormulaCol];
                      else tmpDt.formulas[selectedFormulaCol] = tmp;
                      setDatasheetConfiguration(tmpDt);
                      setSelectedCondition("");
                    }}
                  >
                    <svg
                      class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall css-ptiqhd-MuiSvgIcon-root"
                      focusable="false"
                      aria-hidden="true"
                      viewBox="0 0 24 24"
                      data-testid="CloseIcon"
                    >
                      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                    </svg>
                  </button>
                </li>
              )}
              freeSolo
              renderInput={(params) => (
                <TextField {...params} label="Set condition" />
              )}
              size="small"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="outlined-basic"
              label="Add formula"
              size="small"
              fullWidth
              variant="outlined"
              value={
                datasheetConfiguration?.formulas?.[selectedFormulaCol]?.[
                  selectedCondition
                ] || ""
              }
              onChange={(e) =>
                updateDatasheetConfiguration(
                  "formulas",
                  selectedFormulaCol,
                  e.target.value
                )
              }
            />
            <div style={{ display: "flex", marginTop: "5px" }}>
              <Chip
                variant="outlined"
                label="range higher"
                onClick={() => {
                  updateDatasheetConfiguration(
                    "formulas",
                    selectedFormulaCol,
                    (datasheetConfiguration?.formulas[selectedFormulaCol]?.[
                      selectedCondition
                    ] || "") + " range_high "
                  );
                }}
              />
              <Chip
                variant="outlined"
                label="range lower"
                onClick={() => {
                  updateDatasheetConfiguration(
                    "formulas",
                    selectedFormulaCol,
                    (datasheetConfiguration?.formulas[selectedFormulaCol]?.[
                      selectedCondition
                    ] || "") + " range_low "
                  );
                }}
              />
              <Chip
                variant="outlined"
                label="LC"
                onClick={() => {
                  updateDatasheetConfiguration(
                    "formulas",
                    selectedFormulaCol,
                    (datasheetConfiguration?.formulas[selectedFormulaCol]?.[
                      selectedCondition
                    ] || "") + " lc "
                  );
                }}
              />
              <Chip
                variant="outlined"
                label="accuracy"
                onClick={() => {
                  updateDatasheetConfiguration(
                    "formulas",
                    selectedFormulaCol,
                    (datasheetConfiguration?.formulas[selectedFormulaCol]?.[
                      selectedCondition
                    ] || "") + " accuracy "
                  );
                }}
              />
            </div>
          </Grid>
        </Grid>

        <div>
          <Typography variant="h6" component="h6" style={{ float: "left" }}>
            6. Conditional Formatting
          </Typography>
          <Grid container spacing={1} sx={{ mb: 3 }}>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Datasheet Column
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedFormulaCol1}
                  label="Select column"
                  onChange={(e) => {
                    setSelectedFormulaCol1(e.target.value);
                  }}
                  size="small"
                >
                  {datasheetColumnName.map((columnName, index) => {
                    return (
                      <MenuItem value={"c" + (index + 1)}>
                        {columnName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Condition</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={conditionsCol}
                  label="Select condition"
                  onChange={(e) => {
                    console.log(e.target.value);
                    // setSelectedFormulaCol(e.target.value);
                    setConditionsCol(e.target.value);
                  }}
                  size="small"
                >
                  {[
                    "Greater than",
                    "Less than",
                    "Between",
                    "Precision not equals",
                  ].map((columnName, index) => {
                    return <MenuItem value={index + 1}>{columnName}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Columns
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={dsCol}
                  label="Select column"
                  onChange={(e) => {
                    setDsCol(e.target.value);
                    // setSelectedFormulaCol(e.target.value);
                  }}
                  size="small"
                >
                  {[
                    "range higher",
                    "range lower",
                    "DUC LC",
                    "Master LC",
                    "accuracy",
                    ...datasheetColumnName,
                  ].map((columnName, index) => {
                    // preapre items above datasheet cols
                    let aboveDsItems = 5;
                    if (index < aboveDsItems)
                      return (
                        <MenuItem value={index + 1}>{columnName}</MenuItem>
                      );
                    else
                      return (
                        <MenuItem value={"ds" + (index - aboveDsItems + 1)}>
                          Datasheet: {columnName}
                        </MenuItem>
                      );
                  })}
                </Select>
              </FormControl>
            </Grid>
            {dsCol == 4 ? (
              <Grid item xs={2}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Select Std. Pref.
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={stdRangePref}
                    label="Select column"
                    onChange={(e) => {
                      setStdRangePref(e.target.value);
                      // setSelectedFormulaCol(e.target.value);
                    }}
                    size="small"
                  >
                    {["NA", "1", "2", "3", "4"].map((columnName, index) => {
                      return <MenuItem value={index}>{columnName}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid>
            ) : (
              ""
            )}
            <Grid item xs={1}>
              <input
                type="color"
                value={sketchPickerColor}
                style={{
                  backgroundColor: `${sketchPickerColor}`,
                  width: 70,
                  height: 40,
                  border: "1px solid white",
                  borderRadius: "20px",
                }}
                onChange={(e) => {
                  setSketchPickerColor(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <CButton
                variant="outlined"
                label={"add"}
                onClick={(e) => updateConditionConfiguration("relations")}
              ></CButton>
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ mb: 3 }}>
            <Grid item xs={4} align="left">
              <h6>relations:</h6>
              <table style={{ textAlign: "center" }}>
                <tr>
                  <th>
                    <div style={{ width: "150px" }}>Datasheet Column</div>
                  </th>
                  <th>
                    <div style={{ width: "200px" }}>Condition</div>
                  </th>
                  <th>
                    <div style={{ width: "200px" }}>Target Column</div>
                  </th>
                  <th>
                    <div style={{ width: "100px" }}>Color</div>
                  </th>
                  <th>
                    <div style={{ width: "100px" }}>Action</div>
                  </th>
                </tr>
                {Object.keys(condConfiguration["relations"]).map((dC) => (
                  <tr>
                    {[
                      ...[dC].concat(
                        Object.values(condConfiguration["relations"][dC])
                      ),
                    ]
                      .concat([""])
                      .map((col, idx) => {
                        if (idx === 0)
                          return (
                            <td>
                              {
                                datasheetColumnName[
                                  Number(col.split("c")[1]) - 1
                                ]
                              }
                            </td>
                          );
                        else if (idx === 1) return <td>{condConfObj[col]}</td>;
                        else if (idx === 2)
                          return (
                            <td>
                              {`${
                                [
                                  "range higher",
                                  "range lower",
                                  "DUC LC",
                                  "Master LC",
                                  "accuracy",
                                  ...datasheetColumnName,
                                ][
                                  Number(
                                    col.includes("ds")
                                      ? col.split(".")[0].split("ds")[1]
                                      : col.split(".")[0]
                                  ) -
                                    1 +
                                    (col.includes("ds") ? 5 : 0)
                                ]
                              }${
                                col.split(".")[0] == "4"
                                  ? `.${
                                      col.split(".")[1]
                                        ? Number(col.split(".")[1])
                                        : col.split(".")[1]
                                    }`
                                  : ""
                              }`}
                            </td>
                          );
                        else if (idx === 3)
                          return (
                            <td>
                              <input
                                type="color"
                                value={col}
                                style={{
                                  backgroundColor: `${col}`,
                                  width: 70,
                                  height: 40,
                                  border: "1px solid white",
                                  borderRadius: "20px",
                                }}
                                disabled
                                // onChange={(e) => {
                                //   setSketchPickerColor(e.target.value);
                                // }}
                              />
                            </td>
                          );
                        else if (idx === 4)
                          return (
                            <td>
                              <Button size="sm">
                                <DeleteIcon
                                  style={{
                                    color: "#8d7a7c",
                                  }}
                                  onClick={(e) => {
                                    let tmpC = { ...condConfiguration };
                                    let tmp = { ...tmpC["relations"] };
                                    delete tmp[dC];
                                    tmpC["relations"] = { ...tmp };
                                    setCondConfiguration({ ...tmpC });
                                  }}
                                />
                              </Button>
                            </td>
                          );
                        return <td>{col}</td>;
                      })}
                  </tr>
                ))}
              </table>
            </Grid>
          </Grid>
        </div>

        <div>
          <Typography variant="h6" component="h6" style={{ float: "left" }}>
            7. Set Custom Precision
          </Typography>
          <Grid container spacing={1} sx={{ mb: 3 }}>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Datasheet Column
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedFormulaCol1}
                  label="Select column"
                  onChange={(e) => {
                    setSelectedFormulaCol1(e.target.value);
                  }}
                  size="small"
                >
                  {datasheetColumnName.map((columnName, index) => {
                    return (
                      <MenuItem value={"c" + (index + 1)}>
                        {columnName}
                      </MenuItem>
                    );
                  })}
                  <MenuItem value={"uncertainty"}>{"Uncertainty"}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Target Columns
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={dsCol2}
                  label="Select column"
                  onChange={(e) => {
                    setDsCol2(e.target.value);
                    // setSelectedFormulaCol(e.target.value);
                  }}
                  size="small"
                >
                  {[
                    "range higher",
                    "range lower",
                    "DUC LC",
                    "Master LC",
                    "accuracy",
                    "Greater LC",
                    "Actual Precision",
                    ...datasheetColumnName,
                  ].map((columnName, index) => {
                    // preapre items above datasheet cols
                    let aboveDsItems = 7;
                    if (index < aboveDsItems)
                      return (
                        <MenuItem value={index + 1}>{columnName}</MenuItem>
                      );
                    else
                      return (
                        <MenuItem value={"ds" + (index - aboveDsItems + 1)}>
                          Datasheet: {columnName}
                        </MenuItem>
                      );
                  })}

                  <MenuItem value={"manual"}>Manual</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {dsCol2 == 3 || dsCol2 == 4 ? (
              <Grid item xs={1}>
                <TextField
                  id="outlined-basic"
                  label="+ precision"
                  size="small"
                  fullWidth
                  variant="outlined"
                  value={plusPrecision}
                  onChange={(e) => setPlusPrecision(e.target.value || "")}
                />
              </Grid>
            ) : (
              ""
            )}
            {dsCol2 == 4 || dsCol2 == 6 || dsCol2 == "manual" ? (
              <Grid item xs={2}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Select Std. Pref.
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={stdRangePref}
                    label="Select column"
                    onChange={(e) => {
                      setStdRangePref(e.target.value);
                      // setSelectedFormulaCol(e.target.value);
                    }}
                    size="small"
                  >
                    {["NA", "1", "2", "3", "4"].map((columnName, index) => {
                      if (columnName === "NA")
                        return <MenuItem value={"null"}>{columnName}</MenuItem>;
                      else
                        return <MenuItem value={index}>{columnName}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid>
            ) : (
              ""
            )}
            <Grid item xs={1}>
              <CButton
                variant="outlined"
                label={"add"}
                onClick={(e) => updateCustomPrecision()}
              ></CButton>
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ mb: 3 }}>
            <Grid item xs={4} align="left">
              <h6>Custom Precision mappings: </h6>
              <table style={{ textAlign: "center" }}>
                <tr>
                  <th>
                    <div style={{ width: "150px" }}>source Column</div>
                  </th>
                  <th>
                    <div style={{ width: "200px" }}>Target Column</div>
                  </th>
                  <th>
                    <div style={{ width: "100px" }}>Pref</div>
                  </th>
                  <th>
                    <div style={{ width: "100px" }}>Action</div>
                  </th>
                </tr>

                {Object.keys(customPrecision ? customPrecision : {}).map(
                  (dC) => (
                    <tr>
                      {[...[dC].concat(customPrecision[dC].split("."))]
                        .concat([""])
                        .map((col, idx) => {
                          if (idx === 0)
                            return (
                              <td>
                                {col !== "uncertainty"
                                  ? datasheetColumnName[
                                      Number(col.split("c")[1]) - 1
                                    ]
                                  : col}
                              </td>
                            );
                          else if (idx === 1) {
                            let lt0 = col.includes("ds")
                                ? col
                                    .split("ds")[1]
                                    ?.split(extraPrecisionSplitter)[0] -
                                  1 +
                                  7
                                : col?.split(extraPrecisionSplitter)[0] - 1,
                              lt1 =
                                lt0 == 2 || lt0 == 3
                                  ? col.includes("ds")
                                    ? col
                                        .split("ds")[1]
                                        ?.split(extraPrecisionSplitter)[1]
                                    : col?.split(extraPrecisionSplitter)[1]
                                  : "";
                            lt1 = lt1 ? extraPrecisionSplitter + lt1 : "";
                            return (
                              <td>
                                {col === "manual"
                                  ? col
                                  : [
                                      "range higher",
                                      "range lower",
                                      "DUC LC",
                                      "Master LC",
                                      "accuracy",
                                      "Greater LC",
                                      "Actual Precision",
                                      ...datasheetColumnName,
                                    ][lt0] + lt1}
                              </td>
                            );
                          } else if (idx === 3)
                            return (
                              <td>
                                <Button size="sm">
                                  <DeleteIcon
                                    style={{
                                      color: "#8d7a7c",
                                    }}
                                    onClick={(e) => {
                                      let tmp = { ...customPrecision };
                                      delete tmp[dC];
                                      setCustomPrecision({ ...tmp });
                                    }}
                                  />
                                </Button>
                              </td>
                            );
                          return <td>{col}</td>;
                        })}
                    </tr>
                  )
                )}
              </table>
            </Grid>
          </Grid>
        </div>

        <br />
        <Grid item xs={3} style={{ alignItems: "start" }}>
          <FormControlLabel
            classes={{
              alignItems: "left",
            }}
            style={{ display: "flex" }}
            control={
              <Checkbox
                checked={certificateTableStatus === 0 ? true : false}
                onChange={(e) => {
                  setCertificateTableStatus(e.target.checked == true ? 0 : 1);
                }}
              />
            }
            label="Hide in certificate"
          />
        </Grid>
      </div>

      {/* certificate table confiurations */}
      <div>
        <Divider sx={{ mt: 5 }}>
          <Chip
            label="Certificate table configuration"
            variant="outlined"
            sx={{ fontSize: "16px" }}
          />
        </Divider>
        <br />

        <Typography variant="h6" component="h6" style={{ float: "left" }}>
          add columns
          <CButton
            variant="contained"
            size="small"
            label={<AddIcon />}
            sx={{ ml: 3 }}
            onClick={() => {
              addCertificateColumn();
            }}
          ></CButton>
        </Typography>

        <Grid container spacing={1} sx={{ mb: 3 }}>
          {certificateColumnName.map((column, index) => (
            <Grid item xs={4}>
              <OutlinedInput
                id="outlined-adornment-password"
                label={"column " + (index + 1)}
                value={column}
                placeholder={"column " + (index + 1)}
                onChange={(e) => {
                  updateCertificateColumnName(e.target.value, index);
                }}
                size="small"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        deleteCertificateColumn(index);
                      }}
                      edge="end"
                    >
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Grid>
          ))}
        </Grid>
        <br />
        <div>
          <Typography variant="h6" component="h6" style={{ float: "left" }}>
            2. Define common header
          </Typography>
          <Grid container spacing={1} sx={{ mb: 3 }}>
            <Grid item xs={3}>
              <FormControlLabel
                control={<Checkbox />}
                label="Has common header"
                checked={certificateConfiguration?.hasCommonHeader}
                onChange={(e) => {
                  updateCertificateConfiguration(
                    "hasCommonHeader",
                    null,
                    e.target.value
                  );
                }}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="outlined-basic"
                label="Define common header"
                placeholder="Define common header (, for column separation, $: for empty column)"
                size="small"
                fullWidth
                variant="outlined"
                value={certificateConfiguration?.commonHeaderConfig || ""}
                onChange={(e) =>
                  updateCertificateConfiguration(
                    "commonHeaderConfig",
                    null,
                    e.target.value
                  )
                }
              />
            </Grid>
            <Grid item xs={1}>
              <CButton
                variant="text"
                size="small"
                label={<HelpCenterIcon />}
                onClick={handleOpen}
              ></CButton>
            </Grid>
          </Grid>
        </div>
        <br />
        <div>
          <Typography variant="h6" component="h6" style={{ float: "left" }}>
            Set Relationship
          </Typography>
          <Grid container spacing={1} sx={{ mb: 3 }}>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select datasheet column
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedDatasheetRelCol}
                  label="Select column"
                  onChange={(e) => {
                    setSelectedDatasheetRelCol(e.target.value);
                  }}
                  size="small"
                >
                  {datasheetColumnName.map((columnName, index) => {
                    return (
                      <MenuItem value={"c" + (index + 1)}>
                        {columnName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select certificate column
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedCertRelCol}
                  label="Select column"
                  onChange={(e) => {
                    setSelectedCertRelCol(e.target.value);
                  }}
                  size="small"
                >
                  {certificateColumnName.map((columnName, index) => {
                    return (
                      <MenuItem value={"c" + (index + 1)}>
                        {columnName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1}>
              <CButton
                variant="outlined"
                label={"add"}
                onClick={(e) => updateCertificateConfiguration("relations")}
              ></CButton>
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ mb: 3 }}>
            <Grid item xs={4} align="left">
              <h6>relations:</h6>
              <pre>{JSON.stringify(certificateConfiguration["relations"])}</pre>
            </Grid>
          </Grid>
        </div>
        <Typography variant="h6" component="h6" style={{ float: "left" }}>
          Set formula (optional)
        </Typography>
        <Grid container spacing={1} sx={{ mb: 3 }}>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select column
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedCertFormulaCol}
                label="Select column"
                onChange={(e) => {
                  setSelectedCertFormulaCol(e.target.value);
                }}
                size="small"
              >
                {certificateColumnName.map((columnName, index) => {
                  return (
                    <MenuItem value={"c" + (index + 1)}>{columnName}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={8}>
            <TextField
              id="outlined-basic"
              label="Add formula"
              size="small"
              fullWidth
              variant="outlined"
              value={
                certificateConfiguration?.formulas[selectedCertFormulaCol] || ""
              }
              onChange={(e) =>
                updateCertificateConfiguration(
                  "formulas",
                  selectedCertFormulaCol,
                  e.target.value
                )
              }
            />
          </Grid>
          <Grid item xs={1}>
            <CButton
              variant="text"
              size="small"
              label={<HelpCenterIcon />}
              onClick={handleOpen}
            ></CButton>
          </Grid>
          <br />
          <Grid item xs={3} style={{ alignItems: "start" }}>
            <FormControlLabel
              classes={{
                alignItems: "left",
              }}
              style={{ display: "flex" }}
              control={
                <Checkbox
                  checked={isUncertainty}
                  onChange={(e) => {
                    setUncertainty(e.target.checked);
                  }}
                />
              }
              label="Show Expanded Uncertainty"
            />
          </Grid>
          <br />
          <Grid item xs={4} style={{ alignItems: "start" }}>
            <FormControlLabel
              classes={{
                alignItems: "left",
              }}
              style={{ display: "flex" }}
              control={
                <Checkbox
                  checked={isVerticalUncertainty}
                  onChange={(e) => {
                    setIsVerticalUncertainty(e.target.checked);
                  }}
                />
              }
              label="Show Vertical Expanded Uncertainty"
            />
          </Grid>
          <br />
          <Grid item xs={3} style={{ alignItems: "start" }}>
            <FormControlLabel
              classes={{
                alignItems: "left",
              }}
              style={{ display: "flex" }}
              control={
                <Checkbox
                  checked={isCmc}
                  onChange={(e) => {
                    setIsCmc(e.target.checked);
                  }}
                />
              }
              label="Show Cmc"
            />
          </Grid>
        </Grid>
      </div>

      {/* type-b confiurations */}
      <div>
        <Divider sx={{ mt: 5 }}>
          <Chip
            label="Type-B configuration"
            variant="outlined"
            sx={{ fontSize: "16px" }}
          />
        </Divider>
        <div>
          <Typography variant="h6" component="h6" style={{ float: "left" }}>
            Set Relationship(optional)
          </Typography>
          <Grid container spacing={1} sx={{ mb: 3 }}>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Datasheet column
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedDatasheetRelCol}
                  label="Select column"
                  onChange={(e) => {
                    setSelectedDatasheetRelCol(e.target.value);
                  }}
                  size="small"
                >
                  {datasheetColumnName.map((columnName, index) => {
                    return (
                      <MenuItem value={"c" + (index + 1)}>
                        {columnName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Type-B column
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedTypeBRelCol}
                  label="Select column"
                  onChange={(e) => {
                    setSelectedTypeBRelCol(e.target.value);
                  }}
                  size="small"
                >
                  {uncertaintyFactors.map((uf, index) => {
                    return (
                      <MenuItem value={uf.id} key={uf.id}>
                        {uf.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1}>
              <CButton
                variant="outlined"
                label={"add"}
                onClick={(e) => updateTypeBConfiguration("relations")}
              ></CButton>
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ mb: 3 }}>
            <Grid item xs={4} align="left">
              <h6>relations:</h6>
              <pre>{JSON.stringify(typeBConfiguration["relations"])}</pre>
            </Grid>
          </Grid>
        </div>
        <br />
      </div>

      <div>
        <Divider sx={{ mt: 5 }}>
          <Chip
            label="Budget configuration"
            variant="outlined"
            sx={{ fontSize: "16px" }}
          />
        </Divider>
        <div>
          <Typography variant="h6" component="h6" style={{ float: "left" }}>
            1. Set formula (optional){" "}
            <CButton
              variant="text"
              size="small"
              label={<HelpCenterIcon />}
              onClick={handleOpen}
            ></CButton>
          </Typography>
          <Grid container spacing={1} sx={{ mb: 3 }}>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select column
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedBudgetFormula}
                  label="Select column"
                  onChange={(e) => {
                    setSelectedBudgetFormula(e.target.value);
                  }}
                  size="small"
                >
                  {["uncertainty"].map((columnName, index) => {
                    return (
                      <MenuItem value={"uncertainty"}>{columnName}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <Autocomplete
                value={selectedCondition}
                onChange={(event, newValue) => {
                  if (typeof newValue === "string") {
                    setSelectedCondition(newValue);
                  } else if (newValue && newValue.inputValue) {
                    setSelectedCondition(newValue.inputValue);
                  } else {
                    setSelectedCondition(newValue);
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some(
                    (option) => inputValue === option
                  );
                  if (inputValue !== "" && !isExisting) {
                    filtered.push(inputValue);
                  }

                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="free-solo-with-text-demo"
                options={Object.keys(
                  datasheetConfiguration?.formulas[selectedFormulaCol] || {
                    default: "",
                  }
                )}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === "string") {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                }}
                renderOption={(props, option) => <li {...props}>{option}</li>}
                freeSolo
                renderInput={(params) => (
                  <TextField {...params} label="Set condition" />
                )}
                size="small"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="outlined-basic"
                label="Add formula"
                size="small"
                fullWidth
                variant="outlined"
                value={
                  (datasheetConfiguration?.budget &&
                    datasheetConfiguration?.budget?.formulas &&
                    datasheetConfiguration?.budget?.formulas?.[
                      selectedBudgetFormula
                    ]?.[selectedCondition]) ||
                  ""
                }
                onChange={(e) =>
                  updateDatasheetConfiguration(
                    "budget.formulas",
                    selectedBudgetFormula,
                    e.target.value
                  )
                }
              />
            </Grid>
          </Grid>
        </div>
        <br />
      </div>

      <hr />
      <Toolbar style={{ padding: "0px" }}>
        <CButton
          variant="contained"
          label="SAVE"
          onClick={() => {
            // props.setLoader(true);
            onSubmit();
          }}
        >
          SAVE
        </CButton>
      </Toolbar>
      <ToastContainer />
      {renderHelpModal()}
    </Paper>
  );
}
