import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import { useParams } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";
import { Link } from "react-router-dom";
import { ClassicTable } from "../../utils/components/Styles";
import { showComponent } from "../helper/helpers";
import { generateNumber } from "../../utils/utils";
const axios = require("axios");

const gstTypes = [
  { value: 1, label: "State GST" },
  { value: 2, label: "Central Gst" },
  { value: 3, label: "Non Gst" },
];

export default function CreateAndEditInvoice(props) {
  const [productArray, setProductArray] = React.useState([]);
  const [totalAmount, setTotal] = React.useState("");
  const [clientList, setClientList] = React.useState([]);
  const [grandTotal, setGrandTotal] = React.useState(0);
  const [deletedIds, setDeletedIds] = React.useState([]);
  const userType = localStorage.getItem("type");
  const [defaultReamrk, setDefaultRemark] = React.useState(null);
  const [opTableData, setOpTableData] = React.useState([]);
  const [poDate, setpoDate] = React.useState("");
  const [poNumber, setpoNumber] = React.useState("");
  const [srfNumbers, setsrfNumbers] = React.useState([]);
  const [srfInstrumentIdsNames, setSrfInstrumentIdsNames] = React.useState([]);
  const [instrumentArray, setInstrumentArray] = React.useState([]);
  const params = useParams();
  console.log("params",params)
  const [readingRows, setReadingRows] = React.useState([]);
  const [invoiceObject, updateInvoiceObject] = React.useState({
    subject: "Quotation for Calibration of Equipment",
    challanNo: "",
    challanDate: "",
    productDetails: "",
    totalAmount: "",
    clientId: "",
    quotationNumber: "",
    referenceNumber: "",
    invoiceDate: "",
    invoiceNumber: "",
    gstType: "",
    companyName: "",
    address: "",
    termsConditions: "",
    conveyanceCharges: "",
    courierCharges: "",
    selectedSRF: "",
    equipmentDelivery: "",
    srfInstrumentIdsArray: [],
  });
  const userName = localStorage.getItem("id");
  const readingColumns = [
    [
      {
        field: "id",
        headerName: "Sr. No.",
        editable: false,
        width: 10,
      },
      {
        field: "srfInstrumentId",
        headerName: "Equipment Name",
        editable: true,
      },
      {
        field: "discription",
        headerName: "Description",
        editable: true,
      },
      {
        field: "rate",
        headerName: "Rate",
        editable: true,
      },
      {
        field: "quantity",
        headerName: "Quantity",
        editable: true,
      },
      {
        field: "totalAmount",
        headerName: "Amount",
        editable: true,
      },
      {
        field: "action",
        headerName: "Action",
        editable: true,
      },
    ],
  ];

  var refresh = () => {
    window.location.reload(false);
  };
  const handleSubmit = (event) => {
    // setExecuting(true);
    var totalAmounts = 0;

    if (readingRows.length) {
      readingRows.map((row) => {
        totalAmounts = totalAmounts + row[4];
      });

      var rows1 = readingRows.map((row, i) => ({
        id: parseInt(i),
        srfInstrumentId: parseInt(row[1].id),
        discription: row[2],
        rate: row[3],
        quantity: row[4],
      }));

      var rows = {
        productDetails: JSON.stringify(rows1),
        clientId: parseInt(invoiceObject.companyName.id),
        referenceNumber: invoiceObject.referenceNumber,
        challanNo: invoiceObject.challanNo || "",
        challanDate: invoiceObject.challanDate
          ? moment(invoiceObject.challanDate).format("YYYY-MM-DD")
          : null,
        invoiceNumber: invoiceObject.invoiceNumber || "",
        invoiceDate: invoiceObject.invoiceDate
          ? moment(invoiceObject.invoiceDate).format("YYYY-MM-DD")
          : null,
        poNumber: poNumber || "",
        poDate: poDate ? moment(poDate).format("YYYY-MM-DD") : null,
        subject: invoiceObject.subject,
        serviceReqNumber: invoiceObject.selectedSRF,
        termsConditions: invoiceObject.termsConditions || defaultReamrk,
        equipmentDelivery: invoiceObject.equipmentDelivery,
        gstType: invoiceObject.gstType?.value || "",
        conveyanceCharges: invoiceObject.conveyanceCharges || "",
        courierCharges: invoiceObject.courierCharges || "",
        createdBy: userName,
      };
      var payload = {
        clientId: parseInt(invoiceObject.companyName.id) || "",
        address: invoiceObject.companyName.address || "",
        totalAmount: totalAmount ? totalAmount : 0,
        // discount: totalD,
        gstType: invoiceObject.gstType?.value || "",
        // sgst: sGst ? sGst : 0,
        // cgst: cGst ? cGst : 0,
        subject: invoiceObject.subject || "",
        conveyanceCharges: invoiceObject.conveyanceCharges || "",
        invoiceNumber: invoiceObject.invoiceNumber,
        contactNumber: invoiceObject.companyName.contact || "",
        poNumber: poNumber,
        invoiceDate: invoiceObject.invoiceDate
          ? moment(invoiceObject.invoiceDate).format("YYYY-MM-DD")
          : moment(new Date()).format("YYYY-MM-DD"),
      };
    }
    let url = BASE_URL;
    if (params.invoiceId) {
      axios
        .patch(url + `customInvoice/${invoiceObject.id}`, rows)
        .then((res) => {
          setTimeout(refresh, 500);
          toast("Invoice edit successfully !");
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });

      // axios
      // .patch(url + `paymentMaster`, payload)
      // .then((res) => {
      //   setTimeout(refresh, 500);
      //   toast("Payment Master created successfully !");
      // })
      // .catch((error) => {
      //   toast.error("Something Went Wrong!");
      // });
    } else {
      axios
        .post(url + "customInvoice", rows)
        .then((res) => {
          setTimeout(refresh, 500);
          toast("Invoice created successfully !");
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });

      axios
        .post(url + `paymentMaster`, payload)
        .then((res) => {
          setTimeout(refresh, 500);
          toast("Payment Master created successfully !");
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
    }
  };

  const invoicesData = () => {
    if (invoiceObject.gstType?.value === 1) {
      return (
        <Grid
          container
          spacing={2}
          style={{ marginBottom: "10px", marginTop: "10px" }}
          justifyContent="flex-end"
        >
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="SGST"
              InputLabelProps={{ shrink: true }}
              value={(9 * totalAmount) / 100}
              disabled
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="CGST"
              disabled
              value={(9 * totalAmount) / 100}
              InputLabelProps={{ shrink: true }}
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>
        </Grid>
      );
    } else if (invoiceObject.gstType?.value === 2) {
      return (
        <Grid
          container
          spacing={2}
          style={{ marginBottom: "10px", marginTop: "10px" }}
          justifyContent="flex-end"
        >
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="IGST"
              value={(18 * totalAmount) / 100}
              InputLabelProps={{ shrink: true }}
              disabled
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>
        </Grid>
      );
    } else {
      <Grid
        container
        spacing={2}
        style={{ marginBottom: "10px", marginTop: "10px" }}
        justifyContent="flex-end"
      >
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="SGST"
            InputLabelProps={{ shrink: true }}
            value={0}
            disabled
            size="small"
            fullWidth
            variant="outlined"
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="CGST"
            disabled
            value={0}
            InputLabelProps={{ shrink: true }}
            size="small"
            fullWidth
            variant="outlined"
          />
        </Grid>
      </Grid>;
    }
  };

  const updateCellValue = (rowIndex, colIndex, value) => {
    const newRows = [...readingRows];
    newRows[rowIndex][colIndex] = value;
    setReadingRows([...newRows]);
  };

  const addDescriptionRow = () => {
    let newReadingRows = [...readingRows];
    newReadingRows.push(["", "", "", 0, 0, 0]);
    setReadingRows([...newReadingRows]);
  };

  const deleteReadingRow = async (rowIndex) => {
    let newReadingRows = [...readingRows];
    if (newReadingRows[rowIndex] != undefined) {
      setDeletedIds([...deletedIds, newReadingRows[rowIndex][0]]);
      if (newReadingRows[rowIndex][0] !== "") {
        await axios
          .delete(BASE_URL + `quotation/${newReadingRows[rowIndex][0]}`)
          .then((res) => {
            toast("deleted successfully");
          })
          .catch((err) => {
            console.err("error : ", err);
          });
      }
      newReadingRows.splice(rowIndex, 1);
      setReadingRows([...newReadingRows]);
    }
  };

  const getCustomer = (event) => {
    let url = BASE_URL;
    axios
      .get(url + "clients?_where=(status,eq,1)")
      .then((res) => {
        let pushArray = [];
        res.data.map((client) => {
          pushArray.push({
            id: client.id,
            label: client.companyName,
            contact: client.contact,
            address: client.address,
          });
        });
        setClientList(pushArray);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSRFS = (event) => {
    let url = BASE_URL;
    let data = {
      query: `SELECT serviceReqNumber FROM srfs WHERE clientId =${invoiceObject?.companyName?.id} AND status = 1;`,
    };
    axios
      .post(url + `dynamic`, data)
      .then((res) => {
        let data = res.data;
        let newData = data.map((e) => e.serviceReqNumber);
        setsrfNumbers(newData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // api calls
  async function fetchSettings() {
    axios.get(BASE_URL + `settings`).then((res) => {
      let remark = null;
      let qtNumber = null;
      res.data.map((setting) => {
        if (setting.keyName === "quotation terms") {
          remark = setting.value;
        }
        if (setting.keyName === "QuotationNumber") {
          qtNumber = setting.value;
        }
      });
      !params.quotationId &&
        axios
          .get(BASE_URL + "quotation?_fields=id&_sort=-id&_size=1")
          .then((res) => {
            let qt = generateNumber(
              qtNumber,
              `${res.data.length > 0 ? res.data[0].id + 1 : ""}`
            );
            updateQuoObject("quotationNumber", qt);
          });
      setDefaultRemark(remark);
    });
  }

  const fetchInvoiceDetails = (inpt) => {
    let url = BASE_URL;
    axios
      .get(url + `customInvoice/${params.invoiceId}`)
      .then((res) => {
        let invoiceData = res.data[0];

        let newData = {
          ...invoiceObject,
          id: invoiceData?.id,
          productDetails: invoiceData?.productDetails || "",
          totalAmount: invoiceData?.totalAmount || "",
          clientId: invoiceData?.clientId || "",
          challanNo: invoiceData?.challanNo || "",
          challanDate: invoiceData?.challanDate || "",
          invoiceNumber: invoiceData?.invoiceNumber || "",
          invoiceDate: invoiceData?.invoiceDate || "",
          quotationNumber: invoiceData?.quotationNumber || "",
          referenceNumber: invoiceData?.referenceNumber || "",
          equipmentDelivery: invoiceData?.equipmentDelivery || "",
          poNumber: invoiceData?.poNumber || "",
          poDate: invoiceData?.poDate || "",
          gstType:
            invoiceData?.gstType &&
            gstTypes.filter((e) => e.value == invoiceData?.gstType)[0]
              ? gstTypes.filter((e) => e.value == invoiceData?.gstType)[0]
              : "",
          companyName:
            clientList.filter((e) => e.id == invoiceData?.clientId)[0] || "",
          address: invoiceData?.client_address,
          termsConditions: invoiceData?.termsConditions || "",
          conveyanceCharges: invoiceData?.conveyanceCharges || "",
          courierCharges: invoiceData?.courierCharges || "",
        };
        setTimeout(() => {
          invoiceData && setpoNumber(newData?.poNumber);
          invoiceData && setpoDate(newData?.poDate);
          invoiceData && updateInvoiceObject(newData);
          invoiceData && setGrandTotal(invoiceData?.totalAmount);
        }, 1000);

        let newReadingRows = [];

        if (newData && newData.productDetails) {
          let rows1 = JSON.parse(newData.productDetails);

          const updateReadingRow = rows1?.map((row, id) => [
            id,
            productArray.filter((e) => e.id == row.srfInstrumentId)[0] || "",
            row.discription,
            row.rate,
            row.quantity,
            (row.rate || 0) * (row.quantity || 0),
          ]);

          updateReadingRow?.forEach((item) => {
            newReadingRows.push(item);
          });

          setReadingRows([...newReadingRows]);
        }
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const fetchQutationDetails = (inpt) => {
    let url = BASE_URL;
    axios
      // .get(url + `quotation/${params.quotationId}`)
      .get(
        url +
          `xjoin?_join=qt.quotation,_j,client.clients&_on1=(qt.clientId,eq,client.id)&_fields=qt.productDetails,qt.id,qt.gstType,qt.quotationNumber,qt.referenceNumber,qt.date,qt.enquiryId,qt.enquiryDate,qt.totalAmount,client.companyName,client.contact,client.address,client.id,qt.srfInstrumentId,qt.subject,qt.termsConditions,qt.conveyanceCharges,qt.courierCharges,qt.quotationValidity,qt.equipmentCollection,qt.equipmentDelivery,qt.clientId&_where=(qt.quotationNumber,like,${params.quotationId})`
      )
      .then((res) => {
        let quotationData = res.data[0];
        let newData = {
          ...invoiceObject,
          id: quotationData?.qt_id,
          productDetails: quotationData?.qt_productDetails || "",
          totalAmount: quotationData?.qt_totalAmount || "",
          clientId: quotationData?.qt_clientId || "",
          enquiryId:
            quotationData?.qt_enquiryId && quotationData?.qt_enquiryId != ""
              ? quotationData?.qt_enquiryId
              : "",
          enquiryDate: quotationData?.qt_enquiryDate || "",
          quotationNumber: quotationData?.qt_quotationNumber || "",
          referenceNumber: quotationData?.qt_referenceNumber || "",
          gstType:
            quotationData?.qt_gstType &&
            gstTypes.filter((e) => e.value == quotationData?.qt_gstType)[0]
              ? gstTypes.filter((e) => e.value == quotationData?.qt_gstType)[0]
              : "",
          companyName:
            clientList.filter((e) => e.id == quotationData?.client_id)[0] || "",
          address: quotationData?.client_address,
          termsConditions: quotationData?.qt_termsConditions || "",
          conveyanceCharges: quotationData?.qt_conveyanceCharges || "",
          equipmentDelivery: quotationData?.qt_equipmentDelivery || "",
          courierCharges: quotationData?.qt_courierCharges || "",
        };

        quotationData && updateInvoiceObject(newData);
        quotationData && setGrandTotal(quotationData?.qt_totalAmount);
        let newReadingRows = [];

        if (newData && newData.productDetails) {
          let rows1 = JSON.parse(newData.productDetails);

          const updateReadingRow = rows1?.map((row, id) => [
            id,
            productArray.filter((e) => e.id == row.srfInstrumentId)[0] || "",
            row.discription,
            row.rate,
            row.quantity,
            (row.rate || 0) * (row.quantity || 0),
          ]);
          updateReadingRow?.forEach((item) => {
            newReadingRows.push(item);
          });

          setReadingRows([...newReadingRows]);
        }
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const updateQuoObject = (key, value, q) => {
    let newQuoObject = {
      ...invoiceObject,
    };

    newQuoObject[key] = value;

    updateInvoiceObject({ ...newQuoObject });
  };

  const Calculate = () => {
    let grandTotal = readingRows.reduce(
      (sum, val) => Number(sum) + Number(val[5]),
      0
    );
    grandTotal =
      Number(grandTotal) +
      Number(invoiceObject.conveyanceCharges || 0) +
      Number(invoiceObject.courierCharges || 0);
    setTotal(grandTotal.toFixed(2));
    setGrandTotal(
      (
        grandTotal *
        (invoiceObject.gstType?.value !== 3 &&
        invoiceObject.gstType?.value !== null
          ? 1.18
          : 1)
      ).toFixed(2)
    );
  };

  const getInstrumentsList = () => {
    let url = BASE_URL;
    axios
      .get(url + "instruments")
      .then((res) => {
        setInstrumentArray(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };
  var pushIArray = [];
  for (let i = 0; i < instrumentArray.length; i++) {
    if (instrumentArray[i].instrumentName) {
      pushIArray.push({
        label: instrumentArray[i].id + ", " + instrumentArray[i].instrumentName,
      });
    }
  }

  const getProductList = () => {
    let url = BASE_URL;
    const payload = {
      query:
        "select cp.*, ins.instrumentName from customProducts as cp left join instruments as ins on cp.instrumentId=ins.id;",
    };
    axios
      .post(url + "dynamic", payload)
      .then((res) => {
        let productsArray = [];
        res.data.map((customerProduct) => {
          productsArray.push({
            id: customerProduct.id,
            label: customerProduct.instrumentName,
            rate: customerProduct.rate,
            key: customerProduct.id,
            name: customerProduct.instrumentName,
            instrumentId: customerProduct.instrumentId,
          });
        });
        setProductArray(productsArray);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };
  const getPoAcceptance = () => {
    let url = BASE_URL;
    axios
      .get(url + `poAcceptance`)
      .then((res) => {
        setOpTableData(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const getSrfInstrumentData = () => {
    const url = `${BASE_URL}dynamic`;

    // First API call to get srfInstrumentIdsArray
    if (invoiceObject?.selectedSRF) {
      const payload = {
        query: `SELECT srfInstrumentId FROM datasheets WHERE serviceReqNumber = '${invoiceObject?.selectedSRF}'`,
      };

      axios.post(url, payload).then((res) => {
        let data = res.data;
        let srfInstrumentIdsArray = [
          ...new Set(data?.map((item) => item.srfInstrumentId)),
        ];
        updateQuoObject("srfInstrumentIdsArray", srfInstrumentIdsArray);
      });
    }
  };

  const getSrfInstrumentName = () => {
    const url = `${BASE_URL}dynamic`;
    // Second API call to get instrument names
    if (invoiceObject?.srfInstrumentIdsArray) {
      let Join1 = invoiceObject?.srfInstrumentIdsArray?.join(",");

      const payload1 = {
        query: `SELECT * FROM customProducts WHERE instrumentId IN (${Join1})`,
      };

      if (Join1)
        axios.post(url, payload1).then((res) => {
          let data = res.data;
          setSrfInstrumentIdsNames(data);
        });
    }
  };

  const updateSrfInstrument = () => {
    let newReadingRows = [];

    if (srfInstrumentIdsNames) {
      const updateReadingRow = srfInstrumentIdsNames?.map((row, id) => [
        row.id,
        productArray.filter((e) => e.instrumentId == row.instrumentId)[0] || "",
        row.discription ? row.discription : "",
        row.rate,
        row.quantity ? row.quant : 1,
        (row.rate || 0) * (row.quantity || 1),
      ]);

      updateReadingRow?.forEach((item) => {
        newReadingRows.push(item);
      });
      setReadingRows([...newReadingRows]);
    }
  };

  useEffect(() => {
    getSrfInstrumentName();
  }, [invoiceObject?.srfInstrumentIdsArray]);
  useEffect(() => {
    updateSrfInstrument();
  }, [srfInstrumentIdsNames]);
  useEffect(() => {
    getSrfInstrumentData();
  }, [invoiceObject?.selectedSRF]);
  useEffect(() => {
    getSRFS();
  }, [invoiceObject?.companyName?.id]);
  useEffect(() => {
    getPoAcceptance();
    getInstrumentsList();
    getCustomer();
    getProductList();
    fetchSettings();
  }, []);

  useEffect(() => {
    if (params.invoiceId) fetchInvoiceDetails();
    if (params.quotationId) fetchQutationDetails();
  }, [clientList, productArray]);

  useEffect(() => {
    Calculate();
  }, [
    readingRows,
    invoiceObject.conveyanceCharges,
    invoiceObject.courierCharges,
    invoiceObject.gstType,
  ]);

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Create/Update Invoice
      </Typography>
      <Grid container spacing={2} style={{ marginBottom: "10px" }}>
        <Grid item xs={3}>
          <Autocomplete
            disabled={params.quotationId}
            size="small"
            id="combo-box-demo"
            options={clientList}
            value={invoiceObject.companyName}
            renderInput={(params) => (
              <TextField {...params} label="Company Name *" />
            )}
            onChange={(event, value) => {
              clientList.map((client) => {
                updateQuoObject("companyName", value);
              });
            }}
          />
        </Grid>
        {!params.clientId && (
          <Grid item xs={3}>
            <Autocomplete
              disabled={params.quotationId}
              size="small"
              id="combo-box-demo"
              options={srfNumbers}
              renderInput={(params) => (
                <TextField {...params} label="SRF Number " />
              )}
              onChange={(event, value) => {
                updateQuoObject("selectedSRF", value);
              }}
            />
          </Grid>
        )}

        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Address"
            size="small"
            disabled
            value={invoiceObject?.companyName?.address}
            fullWidth
            InputLabelProps={{ shrink: true }}
            variant="outlined"
          />
        </Grid>
        {params.quotationId && (
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="Quotation Number *"
              size="small"
              value={invoiceObject.quotationNumber || ""}
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>
        )}
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Challan Number *"
            size="small"
            fullWidth
            variant="outlined"
            value={invoiceObject.challanNo}
            onChange={(e) => {
              updateQuoObject("challanNo", e.target.value);
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Challan Date *"
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              value={
                invoiceObject.challanDate
                  ? new Date(invoiceObject.challanDate)
                  : ""
              }
              onChange={(newValue) => {
                updateQuoObject("challanDate", newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Reference Number *"
            size="small"
            value={invoiceObject.referenceNumber || ""}
            fullWidth
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            onChange={(e) => {
              updateQuoObject("referenceNumber", e.target.value);
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Invoice Number *"
            size="small"
            fullWidth
            variant="outlined"
            value={invoiceObject.invoiceNumber}
            onChange={(e) => {
              updateQuoObject("invoiceNumber", e.target.value);
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Invoice Date *"
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              value={
                invoiceObject.invoiceDate
                  ? new Date(invoiceObject.invoiceDate)
                  : ""
              }
              onChange={(newValue) => {
                updateQuoObject("invoiceDate", newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Subject"
            size="small"
            value={invoiceObject.subject || ""}
            InputLabelProps={{ shrink: true }}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateQuoObject("subject", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Terms Conditions"
            InputLabelProps={{ shrink: true }}
            fullWidth
            rows={3}
            multiline
            value={
              invoiceObject.termsConditions
                ? invoiceObject.termsConditions
                : defaultReamrk
            }
            variant="outlined"
            maxRows={5}
            onChange={(e) => {
              updateQuoObject("termsConditions", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            value={invoiceObject?.gstType}
            options={gstTypes}
            renderInput={(params) => (
              <TextField {...params} label="GST Type *" />
            )}
            onChange={(event, val) => {
              updateQuoObject("gstType", val);
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            // value={poNumber}
            options={opTableData}
            getOptionLabel={(option) => option.poNumber}
            renderInput={(params) => (
              <TextField {...params} label="PO Number *" />
            )}
            onChange={(event, newValue) => {
              if (newValue) {
                const selectedpo = opTableData.find(
                  (po) => po.poNumber === newValue.poNumber
                );
                if (selectedpo) {
                  setpoNumber(selectedpo.poNumber);
                  setpoDate(selectedpo.poDate);
                } else {
                  setpoNumber("");
                  setpoDate("");
                }
              } else {
                setpoNumber("");
                setpoDate("");
              }
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="PO Date"
            size="small"
            disabled
            fullWidth
            variant="outlined"
            value={moment(poDate).format("YYYY-MM-DD") || ""}
            onChange={(e) =>
              setpoDate(moment(e.target.value).format("YYYY-MM-DD"))
            }
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Equipment Delivery *"
            size="small"
            value={invoiceObject.equipmentDelivery || ""}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateQuoObject("equipmentDelivery", e.target.value);
            }}
          />
        </Grid>
      </Grid>
      <hr />
      <h4 style={{ "margin-bottom": "0px" }}>Product Details </h4>
      <Button
        style={{ float: "right" }}
        onClick={(e) => {
          addDescriptionRow(0);
        }}
      >
        <AddIcon />
      </Button>
      <div style={{ width: "100%", overflow: "auto" }}>
        <ClassicTable>
          <Table sx={{ minWidth: 660 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {readingColumns[0].map((column) => (
                  <TableCell key={column.field}>
                    {" "}
                    <Typography noWrap>{column.headerName}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {readingRows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  {row.map((cell, cellIndex) => {
                    if (cellIndex == 1) {
                      return (
                        <TableCell style={{ width: "200px" }}>
                          <Autocomplete
                            disabled={readingRows[index][0] !== ""}
                            size="small"
                            id="combo-box-demo"
                            options={productArray}
                            // getOptionLabel={(option) => option.name}
                            value={readingRows[index][cellIndex]}
                            renderInput={(params) => <TextField {...params} />}
                            onChange={(event, value) => {
                              if (value != null) {
                                updateCellValue(index, cellIndex, value);
                                updateCellValue(index, 3, value.rate);
                                updateCellValue(index, 4, 0);
                              }
                            }}
                          />
                        </TableCell>
                      );
                    }
                    if (cellIndex == 2) {
                      return (
                        <TableCell style={{ width: "200px" }}>
                          <Grid item xs={3}>
                            <TextField
                              id="outlined-basic"
                              size="small"
                              fullWidth
                              value={readingRows[index][cellIndex]}
                              InputLabelProps={{ shrink: true }}
                              variant="outlined"
                              onChange={(e) => {
                                updateCellValue(
                                  index,
                                  cellIndex,
                                  e.target.value
                                );
                              }}
                              disabled={readingRows[index][1] === ""}
                            />
                          </Grid>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 3) {
                      return (
                        <TableCell style={{ width: "200px" }}>
                          <Grid item xs={3}>
                            <TextField
                              id="outlined-basic"
                              size="small"
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                              value={readingRows[index][cellIndex]}
                              variant="outlined"
                              onChange={(e) => {
                                if (e.target.value) {
                                  updateCellValue(
                                    index,
                                    cellIndex,
                                    parseInt(e.target.value)
                                  );
                                  updateCellValue(
                                    index,
                                    5,
                                    parseInt(readingRows[index][4]) *
                                      parseInt(e.target.value)
                                  );
                                }
                              }}
                              disabled={readingRows[index][1] === ""}
                            />
                          </Grid>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 4) {
                      return (
                        <TableCell style={{ width: "200px" }}>
                          <Grid item xs={3}>
                            <TextField
                              id="outlined-basic"
                              size="small"
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                              variant="outlined"
                              value={readingRows[index][cellIndex]}
                              onChange={(e) => {
                                if (e.target.value) {
                                  updateCellValue(
                                    index,
                                    cellIndex,
                                    parseInt(e.target.value)
                                  );
                                  updateCellValue(
                                    index,
                                    5,
                                    parseInt(readingRows[index][3]) *
                                      parseInt(e.target.value)
                                  );
                                }
                              }}
                              disabled={readingRows[index][1] === ""}
                            />
                          </Grid>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 5) {
                      return (
                        <TableCell style={{ width: "200px" }}>
                          <Grid item xs={3}>
                            <TextField
                              id="outlined-basic"
                              size="small"
                              fullWidth
                              disabled
                              InputLabelProps={{ shrink: true }}
                              value={
                                readingRows[index][3] * readingRows[index][4]
                              }
                              variant="outlined"
                            />
                          </Grid>
                        </TableCell>
                      );
                    }
                  })}
                  <TableCell>
                    {showComponent("delete") && (
                      <DeleteIcon
                        style={{ color: "#dc3545" }}
                        onClick={(e) => {
                          deleteReadingRow(index, row.id);
                        }}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
              <br />
            </TableBody>
          </Table>
        </ClassicTable>
      </div>
      <Grid
        container
        spacing={2}
        style={{ marginBottom: "10px", marginLeft: "74.5%" }}
      >
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Onsite Charges"
            size="small"
            value={invoiceObject.conveyanceCharges}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateQuoObject("conveyanceCharges", e.target.value);
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        style={{ marginBottom: "10px", marginLeft: "74.5%" }}
      >
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Courier Charges"
            size="small"
            value={invoiceObject.courierCharges}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateQuoObject("courierCharges", e.target.value);
            }}
          />
        </Grid>
      </Grid>
      {invoiceObject.gstType?.value !== 3 &&
      invoiceObject.gstType?.value !== null ? (
        <Grid
          container
          spacing={2}
          style={{ marginBottom: "10px", marginLeft: "74.5%" }}
        >
          <Grid item xs={3}>
            <TextField
              disabled
              id="outlined-basic"
              label="Total Amount"
              size="small"
              value={totalAmount}
              fullWidth
              variant="outlined"
            />
          </Grid>
        </Grid>
      ) : (
        ""
      )}
      {invoiceObject.gstType?.value !== 3 &&
      invoiceObject.gstType?.value !== null
        ? invoicesData()
        : ""}
      <br />
      <Grid
        container
        spacing={2}
        style={{ marginBottom: "10px", marginLeft: "74.5%" }}
      >
        <Grid item xs={3}>
          <TextField
            disabled
            id="outlined-basic"
            label="Grand Total Amount"
            size="small"
            value={grandTotal}
            fullWidth
            variant="outlined"
          />
        </Grid>
      </Grid>
      <br />

      <Toolbar style={{ padding: "0px", overflow: "auto" }}>
        {userType !== "3" ? (
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            onClick={() => {
              invoiceObject.gstType?.value != null
                ? handleSubmit()
                : toast.error("Please select GST Type!");
            }}
          >
            Save
          </Button>
        ) : (
          console.log("")
        )}
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0, ml: 2 }}
          component={Link}
          to={`/Invoice/viewInvoice/${params.clientId}/${params.poNumber}/${params.invoiceId}`}
        >
          Print
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
