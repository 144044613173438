import React, { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import { Box, LinearProgress, Tooltip } from "@mui/material";
import { Form, Button, Card } from "react-bootstrap";
import Modal from "@mui/material/Modal";
import logo from "../image/logo.png";
import "react-toastify/dist/ReactToastify.css";

import "./Login.css";

//API handling components
import { BASE_URL } from "./../../global";
import { useDispatch } from "react-redux";
import { logOut, logIn } from "./loginSlice";
import moment from "moment";
import { useEffect } from "react";
const axios = require("axios");

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: 312,
  bgcolor: "background.paper",
  border: "2px solid cadetblue",
  padding: "20px",
  textAlign: "center",
  borderRadius: "25px",
  display: "flex",
  flexDirection: "column",
};

const data = { userName: "admin", password: "admin" };

const blockTimeConst = 15; //in minutes

function Login(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [timer, setTimer] = useState(null);

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  const configure = (
    _id,
    _userName,
    type,
    modules = "",
    clientId = 0,
    companies,
    branch
  ) => {
    localStorage.clear();
    localStorage.setItem("isLoggedIn", true);
    localStorage.setItem("id", _id);
    localStorage.setItem("clientId", clientId);
    localStorage.setItem("userName", _userName);
    localStorage.setItem("type", type);
    localStorage.setItem("modules", modules);
    localStorage.setItem("validClients", companies);
    localStorage.setItem("branch", branch);

    toast.success("Login Successful!");

    props.setIsLoggedIn(true);
    if (type != 3) navigate("/dashboard", { replace: true });
    else navigate("/clientDashboard", { replace: true });
  };

  const renderModal = () => {
    return (
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        size="md"
      >
        <Box sx={modalStyle}>
          <div
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "24px",
            }}
          >
            <span>Kindly contact your admin to reset your credentials!</span>
          </div>
        </Box>
      </Modal>
    );
  };

  const handleSubmit = (e) => {
    axios
      .get(
        BASE_URL +
          `users?_where=(userName,eq,${userName})~and(password,eq,${password})`
      )
      .then((res) => {
        if (res.status === 200 && res.data.length > 0) {
          // props.setLoader(false);
          axios
            .get(
              BASE_URL +
                `userClientAssign?_where=(userId,eq,${
                  res.data[0].id
                })~and(endDate,gt,${moment(new Date()).format("YYYY-MM-DD")})`
            )
            .then((res2) => {
              console.log("client company mapping : ", res2.data);
              configure(
                res.data[0].id,
                res.data[0].userName,
                res.data[0].type,
                res.data[0].modules,
                res.data[0].clientId,
                res2.data.map((e) => e.companyId).join(","),
                res.data[0].branch
              );
              if (res.data.length)
                dispatch(
                  logIn({
                    user: res.data[0],
                  })
                );
              else dispatch(logOut());
            });
        } else {
          //toast.error("Incorrect Username / password");
          let falseLoginCount = localStorage.getItem("loginCount") || 0;
          // setError(`Incorrect Username / password
          // ${
          //   falseLoginCount < 3 ? `${3 - falseLoginCount} tries remaining` : ""
          // }`);
          setUserName("");
          setPassword("");
          localStorage.setItem("loginCount", Number(falseLoginCount) + 1);
          if (Number(falseLoginCount) === 2) {
            let blockTime = Date.now() + blockTimeConst * 60 * 1000;
            localStorage.setItem("blockTimer", blockTime);
          }
          setIsLoading(false);
          checkTimer();
        }

        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const checkTimer = () => {
    let blockCount = Number(localStorage.getItem("loginCount")) || 0;
    if (blockCount > 2) {
      let blockTime =
        ((Number(localStorage.getItem("blockTimer")) || 0) - Date.now()) / 1000;
      blockTime = blockTime > 0 ? blockTime : 0;
      if (blockTime && !timer)
        setInterval(() => {
          checkTimer();
        }, 1000);
      if (blockTime)
        setError(
          `Please try again after ${parseInt(
            `${blockTime / 60}`
          )} minutes and ${parseInt(`${blockTime % 60}`)} seconds ${
            blockCount < 3 ? `(${3 - blockCount} tries remaining)` : ""
          }`
        );
      else {
        localStorage.setItem("loginCount", 0);
        localStorage.setItem("blockTimer", -1);
        if (timer) clearInterval(timer);
        setError("");
      }
    } else if (blockCount > 0) {
      setError(
        `Incorrect Username / password \n  ${
          blockCount < 3 ? `(${3 - blockCount} tries remaining)` : ""
        }`
      );
    } else setError("");
  };

  useEffect(() => {
    checkTimer();
    return () => {
      if (timer) clearInterval(timer);
    };
  }, []);

  return (
    <>
      <div class="limiter">
        <div class="container-login100">
          <div class="wrap-login100">
            <div class="login100-pic js-tilt" data-tilt>
              <img className="" src={logo} alt="IMG" />
            </div>

            <div class="login100-form validate-form">
              <span class="login100-form-title">Login</span>

              <div
                class="wrap-input100 validate-input"
                data-validate="Valid email is required: ex@abc.xyz"
              >
                <input
                  class="input100"
                  type="text"
                  name="email"
                  placeholder="Username"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  disabled={
                    Number(localStorage.getItem("loginCount") || 0) === 3
                  }
                />
                <span class="focus-input100"></span>
                <span class="symbol-input100">
                  <i class="fa fa-envelope" aria-hidden="true"></i>
                </span>
              </div>

              <div
                class="wrap-input100 validate-input"
                data-validate="Password is required"
              >
                <input
                  class="input100"
                  type="password"
                  name="pass"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  disabled={
                    Number(localStorage.getItem("loginCount") || 0) === 3
                  }
                />
                <span class="focus-input100"></span>
                <span class="symbol-input100">
                  <i class="fa fa-lock" aria-hidden="true"></i>
                </span>
              </div>
              <span style={{ color: "red" }}>{error}</span>

              <div class="container-login100-form-btn">
                <button
                  class="login100-form-btn"
                  onClick={() => {
                    // props.setLoader(true);
                    setIsLoading(true);
                    handleSubmit();
                  }}
                  disabled={
                    isLoading || localStorage.getItem("blockTimer") > Date.now()
                      ? true
                      : false ||
                        (Number(localStorage.getItem("loginCount")) || 0) ===
                          3 ||
                        isLoading
                  }
                >
                  Login
                </button>
              </div>
              {/* <Link to="/clientLogin">
                            <div class="container-login100-form-btn" >
                                <button class="login100-form-btn" style={{backgroundColor:'cornflowerblue'}}>Client Login</button>
                            </div>
                            </Link> */}
              {/* <div>
                                
                                <a class="txt2" style={{fontWeight:'bold',fontSize:'20px'}}>
                                    Client Login
                                </a>
                               
                            </div> */}

              <div class="text-center p-t-12">
                <span class="txt1">Forgot</span>
                <button
                  class="txt2"
                  style={{ backgroundColor: "#0000" }}
                  onClick={(e) => handleOpen(true)}
                >
                  Username / Password?
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {renderModal()}
    </>
  );
}

export default Login;
