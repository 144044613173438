import {
  Autocomplete,
  Box,
  Grid,
  Paper,
  TextField,
  Toolbar,
  Typography,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import axios from "axios";
import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import { ToastContainer, toast } from "react-toastify";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useParams } from "react-router-dom";
import moment from "moment";
import SignatureCanvas from "../../utils/components/SignatureCanvas";

const CreateAndEditCustomerComplaint = () => {
  var [clientArray, setClientArray] = React.useState([]);
    const userName = localStorage.getItem("id");
    const params = useParams();
    const [companyName, setcompanyName]=React.useState("")
  const [complaintObject, createComplaintObject] = React.useState({
    date: null,
    customerId: null,
    description: null,
    acknowledgedBy: null,
    rootCauseAnalysis: null,
    correction: null,
    correctiveAction: null,
    target: null,
    responsibility: null,
    closureDate: null,
    remark: null,
    status: 1 || null,
    createdBy: userName || null,
    approvedby: userName || null,
    preparedBy: userName || null,
    preparedBySign:null,
    approvedBySign:null,
    authorizedBySign:null,

  });

console.log("complaintObject",complaintObject)


  // data operations
  const updateComplaintObject = (key, value) => {
    console.log("Key",key,value)
    let newComplaintObject = {
      ...complaintObject,
    };
    newComplaintObject[key] = value;
    createComplaintObject(newComplaintObject);
  };

  const fetchComplaintDetails = (inpt) => {
    let url = BASE_URL;
    
    axios.get(url + `customerComplaint/${params.id}`)
      .then((res) => {
        const complaintData = res.data[0];

        const companyName = clientArray.find((e) => e.label.split(",")[0] == complaintData?.customerId);
  
        const newData = {
          ...complaintObject,
          id: complaintData?.id,
          date:  complaintData.date
          ? moment(complaintData.date).format("YYYY-MM-DD")
          : null,
          customerId: complaintData?.customerId || "",
          description: complaintData?.description || "",
          acknowledgedBy: complaintData?.acknowledgedBy || "",
          rootCauseAnalysis: complaintData?.rootCauseAnalysis || "",
          correction: complaintData?.correction || "",
          correctiveAction: complaintData?.correctiveAction || "",
          target: complaintData?.target || "",
          responsibility: complaintData?.responsibility || "",
          closureDate: complaintData.closureDate
          ? moment(complaintData.closureDate).format("YYYY-MM-DD")
          : null,
          status: complaintData?.status || "",
          remark: complaintData?.remark,
          createdBy: complaintData?.createdBy || "",
          approvedby: complaintData?.approvedby || "",
        };
  
        complaintData && createComplaintObject(newData);
        complaintData && setcompanyName(companyName); 
  
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };
  

  const getClientIdList = () => {
    let url = BASE_URL;
    axios
      .get(url + "clients?_where=(status,eq,1)")
      .then((res) => {
        let clientData = res.data;

        var pushCArray = [];
        var clientsArrays = [];
        for (let i = 0; i < clientData.length; i++) {
          if (clientData[i].companyName) {
            pushCArray.push({
              label: clientData[i].id + ", " + clientData[i].companyName,
            });
          }
          clientsArrays.push({
            id: clientData[i].id,
            address: clientData[i].address,
          });
          setClientArray(pushCArray);
        }
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };


  const handleSubmit = (event) => {
    let url = BASE_URL;
    if(params.id){
      axios
      .patch(url + `customerComplaint/${params.id}`, complaintObject)
      .then((res) => {
         setTimeout(refresh, 500);
        toast("Customer Complaint edit successfully !");
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      })
    }
    else{
    axios
      .post(url + "customerComplaint", complaintObject)
      .then((res) => {
         setTimeout(refresh, 500);
        toast("Customer Complaint created successfully !");
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
    }
  }

  var refresh = () => {
    window.location.reload(false);
  };

  useEffect(() => {
    getClientIdList();
   if(params.id) fetchComplaintDetails()
  }, []);

  return (
    <div>
      <Paper sx={{ mt: 2, p: 2 }}>
        <Typography variant="h6" component="h6" style={{ float: "left" }}>
          Create And Edit Customer Complaint
        </Typography>
        <Grid container spacing={2} style={{ marginBottom: "10px" }}>
          <Grid item xs={3}>
            <Autocomplete
              size="small"
              id="combo-box-demo"
              // value={companyName || ""}
              options={clientArray}
              renderInput={(params) => (
                <TextField {...params} label="Company Name *" />
              )}
              onInputChange={(event, newInputValue) => {
                updateComplaintObject("customerId", parseInt(newInputValue.split(",")[0]));
                // clientsArrays.map((client) => {
                //   if (client.id === parseInt(newInputValue.split(",")[0])) {
                //     if (client.address) {
                //       setAddress(client.address);
                //       setGstNumber(client.gstNumber);
                //     } else {
                //       setAddress("");
                //       setGstNumber("");
                //     }
                //   }
                // });
              }}
            />
          </Grid>

          <Grid item xs={3}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Date *"
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                value={
                  complaintObject.date ? new Date(complaintObject.date) : ""
                }
                onChange={(newValue) => {
                  updateComplaintObject(
                    "date",
                    moment(newValue).format("YYYY-MM-DD")
                  );
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="Description*"
              size="small"
              value={complaintObject.description || ""}
              fullWidth
              variant="outlined"
              onChange={(e) => {
                updateComplaintObject("description", e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="AcknowledgedBy *"
              size="small"
              value={complaintObject.acknowledgedBy || ""}
              fullWidth
              variant="outlined"
              onChange={(e) => {
                updateComplaintObject("acknowledgedBy", e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="RootCause Analysis*"
              size="small"
              value={complaintObject.rootCauseAnalysis || ""}
              multiline
              minRows={3}
              maxRows={3}
              fullWidth
              variant="outlined"
              onChange={(e) => {
                updateComplaintObject("rootCauseAnalysis", e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="Correction *"
              size="small"
              value={complaintObject.correction || ""}
              multiline
              minRows={3}
              maxRows={3}
              fullWidth
              variant="outlined"
              onChange={(e) => {
                updateComplaintObject("correction", e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="Corrective Action *"
              size="small"
              value={complaintObject.correctiveAction || ""}
              multiline
              minRows={3}
              maxRows={3}
              fullWidth
              variant="outlined"
              onChange={(e) => {
                updateComplaintObject("correctiveAction", e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="Target *"
              size="small"
              value={complaintObject.target || ""}
              fullWidth
              variant="outlined"
              onChange={(e) => {
                updateComplaintObject("target", e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="Responsibility*"
              size="small"
              value={complaintObject.responsibility || ""}
              fullWidth
              variant="outlined"
              onChange={(e) => {
                updateComplaintObject("responsibility", e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={3}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Closure Date *"
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                value={
                  complaintObject.closureDate
                    ? new Date(complaintObject.closureDate)
                    : ""
                }
                onChange={(newValue) => {
                  updateComplaintObject(
                    "closureDate",
                    moment(newValue).format("YYYY-MM-DD")
                  );
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="Remark *"
              size="small"
              value={complaintObject.remark || ""}
              multiline
              minRows={3}
              maxRows={3}
              fullWidth
              variant="outlined"
              onChange={(e) => {
                updateComplaintObject("remark", e.target.value);
              }}
            />
          </Grid>
          <Table style={{ marginTop: 50,}}>
            <TableBody>
              <TableRow>
                <TableCell rowSpan={3} align="center" width={150}>
                
                  <h6
                    style={{
                      textAlign: "left",
                      padding: "5px",
                      fontWeight: "bold",
                    }}
                  >
                   PreparedBy Signature:
                  </h6>
                  <SignatureCanvas
                    updateUrl={(url) => {
                      createComplaintObject({
                        ...complaintObject,
                        preparedBySign: url,
                      });
                    }}
                    url={complaintObject.preparedBySign}
                  />{" "}
                </TableCell>
                <TableCell rowSpan={3} align="center" width={150}>
                
                <h6
                  style={{
                    textAlign: "left",
                    padding: "5px",
                    fontWeight: "bold",
                  }}
                >
                 ApprovedBy Signature:
                </h6>
                <SignatureCanvas
                  updateUrl={(url) => {
                    createComplaintObject({
                      ...complaintObject,
                      approvedBySign: url,
                    });
                  }}
                  url={complaintObject.approvedBySign}
                />{" "}
              </TableCell>
              <TableCell rowSpan={3} align="center"  width={150}>
                
                <h6
                  style={{
                    textAlign: "left",
                    padding: "5px",
                    fontWeight: "bold",
                  }}
                >
                 AuthorizedBy Signature:
                </h6>
                <SignatureCanvas
                  updateUrl={(url) => {
                    createComplaintObject({
                      ...complaintObject,
                      authorizedBySign: url,
                    });
                  }}
                  url={complaintObject.authorizedBySign}
                />{" "}
              </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        <Box>
          <Toolbar>
            <Button
              variant="contained"
              size="small"
             onClick={() => handleSubmit()}
            >
              <b>ADD</b>
            </Button>
          </Toolbar>
        </Box>
      </Paper>
    </div>
  );
};

export default CreateAndEditCustomerComplaint;
