import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Box,
  TableBody,
  ButtonGroup,
  TablePagination,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { BASE_URL } from "../../global";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import PreviewIcon from "@mui/icons-material/Preview";
import EditIcon from "@mui/icons-material/Edit";

const axios = require("axios");
const useStyles = makeStyles({
  table: {
    "& .MuiTableCell-root": {
      border: "1px solid black",
      textAlign: "center",
    },
  },
  tableWithoutBorder: {
    "& .MuiTableCell-root": {
      border: "1px solid black",
      borderBottom: "none !important",
      paddingLeft: "5px",
    },
  },
  row: {
    height: 15,
  },
  gstCell: {
    height: 5,
    border: "none",
  },
  cell: {
    padding: 1,
    width: 180,
    height: 5,
  },
  signCell: {
    padding: 1,
    width: 30,
    alignItems: "center",
    fontStyle: "bold",
  },
  srCell: {
    padding: 1,
    width: 100,
    height: 5,
  },

  nameCell: {
    padding: 1,
    width: 250,
    height: 5,
  },
  makeCell: {
    padding: 1,
    width: 100,
    height: 5,
  },
  rangeCell: {
    padding: 1,
    width: 120,
    height: 5,
  },
  qntCell: {
    padding: 1,
    width: 40,
    height: 5,
  },
  rateCell: {
    padding: 1,
    width: 120,
    height: 5,
  },
  totalCell: {
    padding: 1,
    width: 130,
    height: 5,
  },
});
const ViewDocument = () => {
  const classes = useStyles();
  const [doclist, setDoclist] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const getDocumentData = () => {
    axios
      .get(BASE_URL + "documentUploader")
      .then((res) => {
        setDoclist(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong while fetching standardRanges!");
      });
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this document?"
    );
    if (!confirmDelete) {
      return;
    }
    try {
      axios
        .delete(BASE_URL + `documentUploader/${id}`)
        .then((res) => {
          toast.success("Document Delete Successfully!");
          getDocumentData();
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
    } catch (error) {
      console.error("Error deleting equipment:", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    getDocumentData();
  }, []);

  return (
    <div>
      <Box sx={{ margin: "20px", textAlign: "left" }}>
        <Button
          variant="contained"
          size="small"
          component={Link}
          to="/documentUpload/create/0"
        >
          Add
        </Button>
      </Box>
      <Table mt={5} className={[classes.table, "margin-bottom-5"]}>
        <TableHead className="single-border-table">
          <TableRow>
            <TableCell className={[classes.srCell, "special2"]} align="center">
              <b>Sr. No</b>
            </TableCell>
            <TableCell
              className={[classes.nameCell, "special2"]}
              align="center"
            >
              <b>Document Name </b>
            </TableCell>
            <TableCell
              className={[classes.rateCell, "special2"]}
              align="center"
            >
              <b>Action</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {doclist?.length > 0 ? (
            doclist
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item, ind) => (
                <TableRow key={ind}>
                  <TableCell
                    className={[classes.srCell, "special2"]}
                    align="center"
                  >
                    {ind + 1}
                  </TableCell>
                  <TableCell
                    className={[classes.srCell, "special2"]}
                    align="center"
                  >
                    {item?.documentName}
                  </TableCell>
                  <TableCell
                    className={[classes.srCell, "special2"]}
                    align="center"
                  >
                    <ButtonGroup size="small" aria-label="small button group">
                      <Button
                        component={Link}
                        to={`/documentUpload/create/${item.id}`}
                      >
                        <EditIcon />
                      </Button>

                      <Button onClick={(e) => handleDelete(item.id)}>
                        <DeleteIcon />
                      </Button>
                      <a
                        href={item?.documentURL}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button>
                          <PreviewIcon />
                        </Button>
                      </a>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              ))
          ) : (
            <TableRow>
              <TableCell colSpan={3} align="center">
                <b>Data not found</b>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={doclist.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      ></TablePagination>
    </div>
  );
};

export default ViewDocument;
