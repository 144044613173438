import moment from "moment";

// props : {
//  branch
//  discipline,
//  calibrationProcedureNo
// }
export const generateNumber = (format = "", uid, props) => {
  if (format.includes("DDMMYYYY")) {
    format = format.replace("DDMMYYYY", moment(new Date()).format("DDMMYYYY"));
  }
  if (format.includes("YYYYMMDD")) {
    format = format.replace("YYYYMMDD", moment(new Date()).format("YYYYMMDD"));
  }
  if (format.includes("YYMMDD")) {
    format = format.replace("YYMMDD", moment(new Date()).format("YYMMDD"));
  } else if (format.includes("MMYYYY")) {
    format = format.replace("MMYYYY", moment(new Date()).format("MMYYYY"));
  } else if (format.includes("DD/MM/YYYY")) {
    format = format.replace(
      "DD/MM/YYYY",
      moment(new Date()).format("DD/MM/YYYY")
    );
  } else if (format.includes("DD/MM/YY")) {
    format = format.replace("DD/MM/YY", moment(new Date()).format("DD/MM/YY"));
  } else if (format.includes("YYYY/MM")) {
    format = format.replace("YYYY/MM", moment(new Date()).format("YYYY/MM"));
  } else if (format.includes("YYYY")) {
    format = format.replace("YYYY", moment(new Date()).format("YYYY"));
  } else if (format.includes("DDYY")) {
    format = format.replace("DDYY", moment(new Date()).format("DDYY"));
  } else if (format.includes("DDMM")) {
    format = format.replace("DDMM", moment(new Date()).format("DDMM"));
  } else if (format.includes("MMYY")) {
    format = format.replace("MMYY", moment(new Date()).format("MMYY"));
  } else if (format.includes("MM/YY")) {
    format = format.replace("MM/YY", moment(new Date()).format("MM/YY"));
  } else if (format.includes("M/YY")) {
    format = format.replace("M/YY", moment(new Date()).format("M/YY"));
  } else if (format.includes("YYYY-DD-MM")) {
    format = format.replace(
      "YYYY-DD-MM",
      moment(new Date()).format("YYYY-DD-MM")
    );
  }

  format = format.replace("{no}", uid);
  format = format.replace("{branch}", props?.branch);
  format = format.replace("{discipline}", props?.discipline);
  format = format.replace(
    "{calibrationProcedureNo}",
    props?.calibrationProcedureNo
  );
  return format;
};
