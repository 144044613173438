import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Link, useLocation } from "react-router-dom";

import SummarizeIcon from "@mui/icons-material/Summarize";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Collapse from "@mui/material/Collapse";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Settings from "@mui/icons-material/Settings";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ListSubheader from "@mui/material/ListSubheader";
import ListItemButton from "@mui/material/ListItemButton";
import InfoIcon from '@mui/icons-material/Info';
import StarBorder from "@mui/icons-material/StarBorder";
import DraftsIcon from "@mui/icons-material/Drafts";
import SendIcon from "@mui/icons-material/Send";
import BuildIcon from "@mui/icons-material/Build";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import CategoryIcon from "@mui/icons-material/Category";
import GroupIcon from "@mui/icons-material/Group";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import TableViewIcon from "@mui/icons-material/TableView";
import ArticleIcon from "@mui/icons-material/Article";
import TableChartIcon from "@mui/icons-material/TableChart";
import PodcastsIcon from "@mui/icons-material/Podcasts";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import ReceiptIcon from "@mui/icons-material/Receipt";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import DomainDisabledIcon from "@mui/icons-material/DomainDisabled";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import FeedbackIcon from "@mui/icons-material/Feedback";
import { useNavigate } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { Badge, Menu, MenuItem, Modal, Tooltip } from "@mui/material";
import { BASE_URL, DISABLED_MODULE } from "./../../global";
import axios from "axios";

export const drawerWidth = 220;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Navbar(props) {
  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [masterNested, setMasterNested] = React.useState(false);
  const [srfNested, setSrfNested] = React.useState(false);
  const [paymentNested, setPaymentNested] = React.useState(false);
  const [CommercialNested, setCommercialNested] = React.useState(false);
  const [intermediateProcedure, setIntermediateProcedure] =
    React.useState(false);
  const [rejectedCertificates, setRejectedCertificates] = React.useState([]);
  const location = useLocation();
  const pathname = location.pathname;
  const routeSegments = pathname.split("/");
  let selectedRoute = routeSegments[1];
  if (routeSegments[1] === "master" && routeSegments.length > 1) {
    selectedRoute = routeSegments[2];
  }
  const convertToTitleCase = (str) => {
    return str
      .replace(/([A-Z])/g, ' $1')
      .replace(/^./, (str) => str.toUpperCase());
  };
  
  const selectedRouteInUpperCase = convertToTitleCase(selectedRoute);
  // const selectedRouteInUpperCase = selectedRoute
  const userName = localStorage.getItem("userName");
  const userType = localStorage.getItem("type");
  const modules = localStorage.getItem("modules")?.split(",");
  const [isModalOpen, setModalOpen] = useState(false);

  const handleButtonClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const getRejectedCertificates = () => {
    axios
      .get(BASE_URL + `certificates?_where=(status,eq,4)`)
      .then((res) => setRejectedCertificates(res.data));
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const logout = () => {
    props.setIsLoggedIn(false);
    window.localStorage.clear();
    navigate("/");
    window.location.reload(false);
  };

  const hasAccess = (key) => {
    if (userType == 1) return true;
    else if (userType == 2 || userType == 4) {
      if (modules?.includes(key)) return true;
      else return false;
    }
    // ToDo: add logic for client login
    else {
      if ("".includes(key)) return true;
      else return false;
    }
  };

  const onIdle = () => {
    logout();
  };

  const onActive = () => {};

  const onAction = () => {};

  useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: 15 * 60 * 1000,
    throttle: 250,
    crossTab: true,
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open2 = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (userType == 4) getRejectedCertificates();
  }, []);

  return (
    <>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar variant="dense">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1 }}
            align="left"
          >
            {selectedRouteInUpperCase}
          </Typography>
          <Tooltip title="Nitification">
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open2 ? "rejected-certificates" : undefined}
              aria-haspopup="true"
              aria-expanded={open2 ? "true" : undefined}
              style={{ backgroundColor: "white" }}
            >
              <Badge
                badgeStyle={{
                  top: 12,
                  right: 12,
                  backgroundColor: "white",
                  border: "2px solid black",
                  color: "white",
                  fontWright: 900,
                }}
                color="secondary"
                badgeContent={
                  rejectedCertificates ? `${rejectedCertificates?.length}` : ""
                }
                secondary={true}
              >
                <NotificationsNoneIcon style={{ color: "#1976d2" }} />
              </Badge>
            </IconButton>
          </Tooltip>
          <Button color="inherit" onClick={logout}>
            <AccountCircleIcon />
            &nbsp;
            {userName} &nbsp;
            <span style={{ textTransform: "lowercase" }}>
              ({["admin", "Engineer", "client"][userType - 1]})
            </span>
            &nbsp;&nbsp; |&nbsp;&nbsp; Logout
          </Button>
          <Button color="inherit" onClick={handleButtonClick}>
           <InfoIcon/>
          </Button>
          <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          height: '100%',
        }}
      >
        <div
          style={{
            height: '24px',
            backgroundColor: '#fff',
            position: 'fixed',
            top: '7%',
            right: "0px",
            width: "auto",
            textAlign: 'right',
            boxSizing: 'border-box',
            color: '#fff',
          }}
        >
          <span
            style={{
              backgroundColor: '#000',
              padding: '3px',
            }}
          >
            LABCITY Software version 2.0.0
          </span>
        </div>
      </Modal>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <Typography variant="h6" gutterBottom component="div">
            Calibration
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <List
          sx={{
            width: "100%",
            maxWidth: 360,
            bgcolor: "background.paper",
          }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          {hasAccess("1") && !DISABLED_MODULE?.includes("Dashboard") && (
            <ListItemButton component={Link} to="/dashboard" onClick={() => {}}>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItemButton>
          )}
          {hasAccess("2") && !DISABLED_MODULE?.includes("Master") && (
            <ListItemButton onClick={(e) => setMasterNested(!masterNested)}>
              <ListItemIcon>
                <FormatListBulletedIcon />
              </ListItemIcon>
              <ListItemText primary="Master" />
              {masterNested ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          )}
          <Collapse in={masterNested} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/master/masterEQP"
                onClick={() => {}}
              >
                <ListItemIcon>
                  <BuildIcon />
                </ListItemIcon>
                <ListItemText primary="Master EQP" />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/master/instrument"
                onClick={() => {}}
              >
                <ListItemIcon>
                  <PrecisionManufacturingIcon />
                </ListItemIcon>
                <ListItemText primary="Instrument" />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/master/discipline"
                onClick={() => {}}
              >
                <ListItemIcon>
                  <ArchitectureIcon />
                </ListItemIcon>
                <ListItemText primary="Discipline" />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/master/product"
                onClick={() => {}}
              >
                <ListItemIcon>
                  <ArchitectureIcon />
                </ListItemIcon>
                <ListItemText primary="Price List" />
              </ListItemButton>
              {/* <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/master/range"
                onClick={() => {}}>
                <ListItemIcon>
                  <ArchitectureIcon />
                </ListItemIcon>
                <ListItemText primary="Range" />
              </ListItemButton> */}

              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/master/client"
                onClick={() => {}}
              >
                <ListItemIcon>
                  <GroupIcon />
                </ListItemIcon>
                <ListItemText primary="Client" />
              </ListItemButton>
              {/* <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/master/table"
                onClick={() => {}}
              >
                <ListItemIcon>
                  <TableChartIcon />
                </ListItemIcon>
                <ListItemText primary="Table" />
              </ListItemButton> */}
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/master/unit/unitList"
                onClick={() => {}}
              >
                <ListItemIcon>
                  <TableChartIcon />
                </ListItemIcon>
                <ListItemText primary="Units" />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/master/template"
                onClick={() => {}}
              >
                <ListItemIcon>
                  <TableChartIcon />
                </ListItemIcon>
                <ListItemText primary="Template" />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/master/datasheetTemplate"
                onClick={() => {}}
              >
                <ListItemIcon>
                  <TableChartIcon />
                </ListItemIcon>
                <ListItemText primary="Datasheet Template" />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/master/uncertinity/uncertinityList"
                onClick={() => {}}
              >
                <ListItemIcon>
                  <TableChartIcon />
                </ListItemIcon>
                <ListItemText primary="Uncertainty" />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/master/supportiveInstruments/supportiveInstrumentsList"
                onClick={() => {}}
              >
                <ListItemIcon>
                  <TableChartIcon />
                </ListItemIcon>
                <ListItemText primary="Supportive Inst." />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/master/setting"
                onClick={() => {}}
              >
                <ListItemIcon>
                  <TableChartIcon />
                </ListItemIcon>
                <ListItemText primary="Settings" />
              </ListItemButton>
            </List>
          </Collapse>

          <List component="div" disablePadding>
            <ListItemButton
              // sx={{ pl: 4 }}
              component={Link}
              to="/srf"
              onClick={() => {}}
            >
              <ListItemIcon>
                <PrecisionManufacturingIcon />
              </ListItemIcon>
              <ListItemText primary="SRF" />
            </ListItemButton>
          </List>

          {hasAccess("4") && !DISABLED_MODULE?.includes("Datasheets") && (
            <ListItemButton component={Link} to="/datasheet" onClick={() => {}}>
              <ListItemIcon>
                <TableViewIcon />
              </ListItemIcon>
              <ListItemText primary="Datasheets" />
            </ListItemButton>
          )}
          {hasAccess("5") && !DISABLED_MODULE?.includes("Certificates") && (
            <ListItemButton
              component={Link}
              to="/certificate"
              onClick={() => {}}
            >
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary="Certificates" />
            </ListItemButton>
          )}
          {/* {hasAccess("") && (
            <ListItemButton
              component={Link}
              to="/challan/challanList"
              onClick={() => {}}
            >
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary="Challan List" />
            </ListItemButton>
          )} */}

          {hasAccess("7") && !DISABLED_MODULE?.includes("Courier Register") && (
            <ListItemButton
              component={Link}
              to="/courier/courierList"
              onClick={() => {}}
            >
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary="Courier Register" />
            </ListItemButton>
          )}
          {hasAccess("8") && !DISABLED_MODULE?.includes("Expenses") && (
            <ListItemButton
              component={Link}
              to="/master/expense"
              onClick={() => {}}
            >
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary="Expenses" />
            </ListItemButton>
          )}
          {hasAccess("9") && !DISABLED_MODULE?.includes("Work Report") && (
            <ListItemButton
              component={Link}
              to="/workReport"
              onClick={() => {}}
            >
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary="Work Report" />
            </ListItemButton>
          )}
          {hasAccess("10") && !DISABLED_MODULE?.includes("Summary Report") && (
            <ListItemButton
              component={Link}
              to="/summaryReport"
              onClick={() => {}}
            >
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary="Summary Report" />
            </ListItemButton>
          )}

          {hasAccess("11") && !DISABLED_MODULE?.includes("History Card") && (
            <ListItemButton
              component={Link}
              to="/historyCard"
              onClick={() => {}}
            >
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary="History Card" />
            </ListItemButton>
          )}
          {hasAccess("12") && !DISABLED_MODULE?.includes("Scheduler") && (
            <ListItemButton
              component={Link}
              to="/scheduler"
              onClick={() => {}}
            >
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary="Scheduler" />
            </ListItemButton>
          )}
          {hasAccess("14") && !DISABLED_MODULE?.includes("Commercial") && (
            <ListItemButton
              onClick={(e) => setCommercialNested(!CommercialNested)}
            >
              <ListItemIcon>
                <FormatListBulletedIcon />
              </ListItemIcon>
              <ListItemText primary="Commercial" />
              {CommercialNested ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          )}
          <Collapse in={CommercialNested} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/master/enquiry/enquiryList"
                onClick={() => {}}
              >
                <ListItemIcon>
                  <NewspaperIcon />
                </ListItemIcon>
                <ListItemText primary="Enquiry" />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/enquiry/followUpReport"
                onClick={() => {}}
              >
                <ListItemIcon>
                  <BookmarkIcon />
                </ListItemIcon>
                <ListItemText primary="Enquiry Followup" />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/quotation"
                onClick={() => {}}
              >
                <ListItemIcon>
                  <RequestQuoteIcon />
                </ListItemIcon>
                <ListItemText primary="Quotation" />
              </ListItemButton>

              
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/poAcceptance"
                onClick={() => {}}
              >
                <ListItemIcon>
                  <PodcastsIcon />
                </ListItemIcon>
                <ListItemText primary="poAcceptance" />
              </ListItemButton>

              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/viewInvoice"
                onClick={() => {}}
              >
                <ListItemIcon>
                  <ReceiptIcon />
                </ListItemIcon>
                <ListItemText primary="Invoice" />
              </ListItemButton>

            </List>
          </Collapse>

          {hasAccess("17") && !DISABLED_MODULE?.includes("Payment") && (
            <ListItemButton onClick={(e) => setPaymentNested(!paymentNested)}>
              <ListItemIcon>
                <FormatListBulletedIcon />
              </ListItemIcon>
              <ListItemText primary="Payment" />
              {paymentNested ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          )}
          <Collapse in={paymentNested} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/paymentMaster"
                onClick={() => {}}
              >
                <ListItemIcon>
                  <BuildIcon />
                </ListItemIcon>
                <ListItemText primary="Payment History" />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/accountStatement"
                onClick={() => {}}
              >
                <ListItemIcon>
                  <PrecisionManufacturingIcon />
                </ListItemIcon>
                <ListItemText primary="Account Statement" />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/gstReport"
                onClick={() => {}}
              >
                <ListItemIcon>
                  <PrecisionManufacturingIcon />
                </ListItemIcon>
                <ListItemText primary="Gst Report" />
              </ListItemButton>
            </List>
          </Collapse>
          {hasAccess("18") && !DISABLED_MODULE?.includes("Purchase") && (
            <ListItemButton
              component={Link}
              to="/master/purchase/purchaseList"
              onClick={() => {}}
            >
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary="Purchase" />
            </ListItemButton>
          )}
          {/* {hasAccess("19") && (
            <ListItemButton
              onClick={(e) => setIntermediateProcedure(!intermediateProcedure)}>
              <ListItemIcon>
                <FormatListBulletedIcon />
              </ListItemIcon>
              <ListItemText primary="Intermediate Procedure" />
              {intermediateProcedure ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          )} */}
          {/* {hasAccess("20") && (
            <ListItemButton
              component={Link}
              to="/master/scope/scopes"
              onClick={() => {}}>
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary="Nable Scope" />
            </ListItemButton>
          )} */}

          {hasAccess("21") && !DISABLED_MODULE?.includes("Master In/Out") && (
            <ListItemButton
              component={Link}
              to="/standardInOutList"
              onClick={() => {}}
            >
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary="Master In/Out" />
            </ListItemButton>
          )}

          {/* {hasAccess("23") && (
            <ListItemButton
              component={Link}
              to="/feedback/feedbackReport"
              onClick={() => {}}
            >
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary="Feedback Report" />
            </ListItemButton>
          )}

          {hasAccess("24") && (
            <ListItemButton
              component={Link}
              to="/master/pickup/pickupList"
              onClick={() => {}}
            >
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary="Pickup" />
            </ListItemButton>
          )} */}
          {hasAccess("25") && userType == 1 && !DISABLED_MODULE?.includes("Customer Complaints") && (
            <ListItemButton
              component={Link}
              to="/complaintsList"
              onClick={() => {}}
            >
              <ListItemIcon>
                <DomainDisabledIcon />
              </ListItemIcon>
              <ListItemText primary="Customer Complaints" />
            </ListItemButton>
          )}
          {hasAccess("25") && userType == 1 && !DISABLED_MODULE?.includes("Customer Feedback") && (
            <ListItemButton
              component={Link}
              to="/feedbackList"
              onClick={() => {}}
            >
              <ListItemIcon>
                <FeedbackIcon />
              </ListItemIcon>
              <ListItemText primary="Customer Feedback" />
            </ListItemButton>
          )}
          {hasAccess("25") && userType == 1 && !DISABLED_MODULE?.includes("User") && (
            <ListItemButton
              component={Link}
              to="/master/users/userList"
              onClick={() => {}}
            >
              <ListItemIcon>
                <ManageAccountsIcon />
              </ListItemIcon>
              <ListItemText primary="User" />
            </ListItemButton>
          )}
          {hasAccess("-1") && userType == 1 && !DISABLED_MODULE?.includes("Audit") && (
            <ListItemButton component={Link} to="/audit" onClick={() => {}}>
              <ListItemIcon>
                <SummarizeIcon />
              </ListItemIcon>
              <ListItemText primary="Audit" />
            </ListItemButton>
          )}
           {hasAccess("25") && userType == 1 &&  !DISABLED_MODULE?.includes("Document Upload") && (
            <ListItemButton
              component={Link}
              to="/documentUpload"
              onClick={() => {}}
            >
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary="Document Upload" />
            </ListItemButton>
          )}
          <Collapse in={intermediateProcedure} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/master/intermediateProcedure"
                onClick={() => {}}
              >
                <ListItemIcon>
                  <BuildIcon />
                </ListItemIcon>
                <ListItemText primary="Intermediate Procedure" />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/master/checkRecords"
                onClick={() => {}}
              >
                <ListItemIcon>
                  <PrecisionManufacturingIcon />
                </ListItemIcon>
                <ListItemText primary="Check Records" />
              </ListItemButton>
            </List>
          </Collapse>
        </List>
      </Drawer>
      <Menu
        anchorEl={anchorEl}
        id="rejected-certificates"
        open={open2}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {rejectedCertificates.map((rC) => (
          <MenuItem
            onClick={() => {
              window.location.assign(
                `${window.location.href.split("/#/")[0]}/#/certificate/edit/${
                  rC.id
                }`
              );
            }}
          >
            Certificate {rC.certificateNumber}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
