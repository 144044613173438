// functionalities Check

import moment from "moment";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import html2pdf from "html2pdf.js";
import { toPng } from "html-to-image";
import { toast } from "react-toastify";
import {
  Button,
  Grid,
  Table,
  TableRow,
  TextField,
  Toolbar,
} from "@mui/material";
import { useSnackbar } from "notistack";
import QRCode from "react-qr-code";
import { BASE_URL, DOMAIN } from "../../global";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MuiTableCell from "@material-ui/core/TableCell";
import { Autocomplete } from "@mui/material";

import UnitechHeader from "../image/certificate_header.png";
import UnitechFooter from "../image/certificate_footer.png";

import "./certificate.css";
import "./certificateList.css";
import RenderStaticTables from "./renderTables";

const TableCell = withStyles({
  root: {
    border: "none",
    padding: "0px",
    margin: "0px",
    lineHeight: "1.32",
    fontSize: "12px",
    paddingLeft: "5px",
  },
})(MuiTableCell);

const axios = require("axios");
const useStyles = makeStyles({
  table: {
    border: "1px solid black",
    padding: "1px",
  },
  infoCell: {
    maxHeight: "5px",
  },
  innerCell: {
    padding: "0px",
    maxWidth: "40%",
  },
  formatedCell: {
    fontSize: "0.82em",
    padding: "0px",
    margin: "0px",
  },
  certificateCell: {
    fontSize: "0.82em",
    padding: "0px",
    margin: "0px",
    width: "20%",
  },
  sxPad: {
    padding: "5px",
  },
  addressCell: {
    width: "200px",
  },
});
export default function ViewCertificate(props) {
  const printComponentRef = React.useRef();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { certificateId: paramCertificateId } = useParams();
  const certificateId = props.certificateIds || paramCertificateId;

  const [instrument, setInstrument] = React.useState(null);
  const [configuration, setConfiguration] = React.useState({});
  const [clientId, setClientId] = React.useState("");
  const [instrumentArray, setInstrumentArray] = React.useState([]);
  const [standardMasterArray, setStandardMasterArray] = React.useState([]);
  const [instrumentName, setInstrumentName] = React.useState("");
  const [requestedName, setRequestedName] = React.useState("");
  const [make, setMake] = React.useState("");
  const [calibrationProcedureNo, setCalibrationProcedureNo] =
    React.useState("");
  const [referenceStandards, setReferenceStandards] = React.useState("");
  const [lc, setLC] = React.useState("");
  const [dcNumber, setDcNumber] = React.useState("");
  const [ranges, setRanges] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [calibrationDate, setCalibrationDate] = React.useState(null);
  const [dcDate, setDcDate] = React.useState(null);
  const [nextDueDate, setNextDueDate] = React.useState(null);
  const [receiptDate, setReceiptDate] = React.useState(null);
  const [poDate, setPoDate] = React.useState(null);
  const [dateOfIssue, setDateOfIssue] = React.useState("");
  const [calibrationFrequency, setCalibrationFrequency] = React.useState(null);

  const [calProcRefNo, setCalProcRefNo] = React.useState("");
  const [mfgNo, setMfgNo] = React.useState("");
  const [rangeDbData, setRangeData] = React.useState([]);
  // const [allRangeData, setRangeDataArray] = React.useState([]);
  const [specificValuesFromTable, setSpecificValuesFromTable] = React.useState(
    []
  );
  const [status, setStatus] = React.useState(-1);
  const [identificationNo, setIdentificationNo] = React.useState("");
  const [DUCID, setDUCID] = React.useState([]);
  const [calType, setCalType] = React.useState("");
  const [location, setLocation] = React.useState("");
  const [accuracy, setAccuracy] = React.useState("");
  const [filterRangeData, setFilterRangeData] = React.useState([]);
  const [serialNo, setSerialNo] = React.useState("");
  const [selectedStandards, setSelectedStandards] = React.useState("");
  const [ulrNumber, setUlrNumber] = React.useState(null);
  const [certificateNumber, setCertificateNumber] = React.useState("");
  const [serviceReqNumber, setServiceReqNumber] = React.useState("");
  const [jobNumber, setJobNumber] = React.useState("");
  const [workOrderNumber, setWorkOrderNumber] = React.useState("");
  const [poNo, setPoNo] = React.useState(null);
  const [startTemp, setStartTemp] = React.useState("");
  const [endTemp, setEndTemp] = React.useState("");
  const [tempDiff, setTempDiff] = React.useState("");
  const [startHumidity, setStartHumidity] = React.useState("");
  const [endHumidity, setEndHumidity] = React.useState("");
  const [humDiff, setHumDiff] = React.useState("");
  const [models, setModel] = React.useState("");
  const [ConOfDuc, setConOfDuc] = React.useState("");
  const [calPoint, setCalPoint] = React.useState("");
  const [calMethod, setCalMethod] = React.useState("");
  const [locationOfInstrument, setLocationOfInstrument] = React.useState("");
  const [srnNo, setSrnNo] = React.useState("");
  const [atmosphericPressure, setAtmosphericPressure] = React.useState("");
  const [disciplineName, setDisciplineName] = React.useState("");
  const [defaultReamrk, setDefaultRemark] = React.useState(null);
  const userType = localStorage.getItem("type");
  const [tableDisplayStatus, setTableDisplayStatus] = React.useState(1);
  const [settingList, setSettingList] = React.useState({});
  const [isPrinting, setisPrinting] = React.useState(!!props.certificateIds);
  const [id, setId] = React.useState(-1);
  const [approvedby, setApprovedby] = React.useState("");
  const [calibratedby, setCalibratedby] = React.useState("");
  const user = [localStorage.getItem("id"), localStorage.getItem("userName")];
  const [letterHead, setLetterHead] = React.useState(true);
  const [electronicSign, setElectronicSign] = React.useState(true);
  const [extraColumns, setExtraColumns] = React.useState([]);
  const [departmet, setDepartment] = React.useState("");
  const [input, setInput] = React.useState("");
  const [operatingrange, setOperatingrand] = React.useState("");
  const [revisionNumber, setRevisionNumber] = React.useState("");
  const [amendmnetHistory, setAmendmentHistory] = React.useState("");
  const [calibrationReason, setCalibrationReason] = React.useState(1);

  const [complianceStatus, setComplianceStatus] = React.useState(null);
  const [compliance, setCompliance] = React.useState(false);
  const [lastModified, setLastModified] = React.useState("");
  const [showAmendment, setShowAmendment] = React.useState(false);
  const [instrumentId, setInstrumentId] = React.useState("");
  const [deletedTableIds, setDeletedTableIds] = React.useState("");

  // api calls
  function fetchSettings() {
    axios.get(BASE_URL + `settings`).then((res) => {
      let remark = null,
        complance = false,
        settings = res.data;
      let _settingList = {};
      settings.forEach((setting) => {
        if (setting?.keyName?.toLowerCase() === "remarks") {
          remark = setting.value;
        } else if (
          setting.keyName === "Compliance Status" &&
          setting.value.toLowerCase() === "true"
        ) {
          complance = true;
        }
        _settingList[setting.keyName] = setting.value;
      });
      _settingList["amendment"] = _settingList["amendment"].toLowerCase();
      setSettingList(_settingList);
      // setDefaultRemark(remark);
      setCompliance(complance);
    });
  }
  // todo : move to renderrangedata file
  const parseRange = (rangeVal) => {
    let tmp = rangeVal
      ? rangeVal.split("||").map((e) => {
          let tmp2 = e
            .trim()
            .split("|")
            .map((e2) => e2.trim());
          let tmpUnit = tmp2[0].split("#").map((e2) => e2.trim());
          tmpUnit = tmpUnit[1] ? tmpUnit[1] : "";
          tmp2[0] = tmp2[0].split("#")[0].trim();
          if (tmp2[1]) {
            tmp2[1] = tmp2[1].split("#")[0].trim();
          }
          tmp2 = tmp2.join(" to ");
          return `${tmp2}${tmpUnit.length > 0 ? ` ${tmpUnit}` : ""}`;
        })
      : [];
    return tmp.join(" , ");
  };

  function fetchCertificate() {
    let url = BASE_URL;
    axios
      .get(
        url +
          `xjoin?_join=cer.certificates,_j,ins.instruments,_j,cust.clients,_j,disc.discipline&_on1=(cer.instrumentId,eq,ins.id)&_on2=(cust.id,eq,cer.clientId)&_on3=(ins.disciplineId,eq,disc.id)&_fields=cer.lastModified,cer.approvedby,cer.status,cer.clientId,cer.location,cer.id,cer.instrumentId,cer.lc,cer.make,cer.model,cer.ConOfDuc,cer.ranges,cer.mfgNo,cer.deletedTableIds,cust.companyName,ins.instrumentName,ins.standardMasterIds,ins.calibrationProcedureNo,ins.referenceStandards,cust.address,cer.dcNumber,cer.calibrationDate,cer.dcDate,cer.nextDueDate,cer.receiptDate,cer.calProcRefNo,cer.mfgNo,cer.identificationNo,cer.DUCID,cer.DUCID,cer.calibrationType,cer.specificValues,cer.locationOfInstrument,cer.serialNumber,cer.additionalStandardIds,cer.serviceReqNumber,cer.jobNumber,cer.accuracy,cer.poNumber,cer.poDate,cer.startTemp,cer.endTemp,cer.tempDiff,cer.startHumidity,cer.endHumidity,cer.humDiff,cer.dateOfIssue,cer.model,cer.calPoint,cer.calMethod,cer.locationOfInstrument,cer.srnNo,cer.atmosphericPressure,cer.configuration,cer.workOrderNumber,cer.certificateNumber,cer.ULRNo,cer.tableDisplayStatus,disc.name,disc.remarks,cer.extraColumns,cer.calibrationReason,cer.complianceStatus&_where=(cer.id,eq, ${certificateId})`
      )
      .then((res) => {
        if (res.data.length && res.data[0] != null) {
          setLastModified(res.data[0].cer_lastModified);
          setApprovedby(
            res.data[0].cer_approvedby ? res.data[0].cer_approvedby : ""
          );
          setStatus(res.data[0].cer_status);
          setId(res.data[0].cer_id);
          setClientId(res.data[0].cust_companyName);
          setInstrumentName(res.data[0].ins_instrumentName);
          setMake(res.data[0].cer_make);
          setModel(res.data[0].cer_model);
          setLC(res.data[0].cer_lc);
          setDefaultRemark(res.data[0].disc_remarks);
          setRanges(parseRange(res.data[0].cer_ranges));
          setReferenceStandards(res.data[0].ins_referenceStandards);
          setSelectedStandards(
            res.data[0].cer_additionalStandardIds?.split(",")
          );
          setCalibrationProcedureNo(res.data[0].ins_calibrationProcedureNo);
          setAddress(res.data[0].cust_address);
          setDcNumber(res.data[0].cer_dcNumber);
          setUlrNumber(res.data[0].cer_ULRNo);
          setCertificateNumber(res.data[0].cer_certificateNumber);
          // setCalibrationDate(res.data[0].cer_calibrationDate)
          setDcDate(res.data[0].cer_dcDate);
          setNextDueDate(res.data[0].cer_nextDueDate);
          setReceiptDate(res.data[0].cer_receiptDate);
          setCalProcRefNo(res.data[0].cer_calProcRefNo);
          setMfgNo(res.data[0].cer_mfgNo);
          setIdentificationNo(res.data[0].cer_identificationNo);
          setDUCID(res.data[0].cer_DUCID);
          setCalType(res.data[0].cer_calibrationType);
          res.data[0].cer_specificValues
            ? setSpecificValuesFromTable(
                res.data[0].cer_specificValues.split(",")
              )
            : setSpecificValuesFromTable([]);
          setLocation(res.data[0].cer_location);
          setAccuracy(res.data[0].cer_accuracy);
          setSerialNo(res.data[0].cer_serialNumber);
          setServiceReqNumber(res.data[0].cer_serviceReqNumber);
          setJobNumber(res.data[0].cer_jobNumber);
          setWorkOrderNumber(res.data[0].cer_workOrderNumber);
          setPoNo(res.data[0].cer_poNumber);
          setPoDate(res.data[0].cer_poDate);
          setStartTemp(res.data[0].cer_startTemp);
          setEndTemp(res.data[0].cer_endTemp);
          setTempDiff(res.data[0].cer_tempDiff);
          setStartHumidity(res.data[0].cer_startHumidity);
          setEndHumidity(res.data[0].cer_endHumidity);
          setHumDiff(res.data[0].cer_humDiff);
          setDateOfIssue(res.data[0].cer_dateOfIssue);
          setModel(res.data[0].cer_model);
          setConOfDuc(res.data[0].cer_ConOfDuc);
          setCalPoint(res.data[0].cer_calPoint);
          setCalMethod(res.data[0].cer_calMethod);
          setLocationOfInstrument(res.data[0].cer_locationOfInstrument);
          setSrnNo(res.data[0].cer_srnNo);
          setAtmosphericPressure(res.data[0].cer_atmosphericPressure);
          setConfiguration(JSON.parse(res.data[0].cer_configuration));
          setDisciplineName(res.data[0].disc_name);
          setTableDisplayStatus(res.data[0].cer_tableDisplayStatus);
          let extraEvvtries = res.data[0].cer_extraColumns
            ? JSON.parse(res.data[0].cer_extraColumns)
            : {};
          setInput(extraEvvtries["input"]);
          extraEvvtries["operating range"] = parseRange(
            extraEvvtries["operating range"]
          );
          setOperatingrand(extraEvvtries["operating range"]);
          setDepartment(extraEvvtries["department"]);
          setExtraColumns(
            Object.keys(extraEvvtries).length
              ? Object.entries(extraEvvtries)
              : []
          );
          setCalibrationReason(res.data[0].cer_calibrationReason);
          setComplianceStatus(res.data[0].cer_complianceStatus);
        }

        setInstrumentId(res.data[0].cer_instrumentId);
        setDeletedTableIds(res.data[0].cer_deletedTableIds);

        // fetchRangesData(res.data[0].cer_instrumentId);

        fetchInstrument(res.data[0].cer_instrumentId);
        if (res.data[0].cer_additionalStandardIds) {
          let standardMasterIds = res.data[0].cer_additionalStandardIds;

          var splitStData = standardMasterIds.split(",");
          var combineArray = [];
          splitStData.forEach(function (element) {
            var splitByCol = element.split(":")[0];
            combineArray.push(splitByCol);
          });
          axios
            .get(url + `standards?_where=(id,in,${combineArray.toString()})`)
            .then((res) => {
              setStandardMasterArray(res.data);
            });
        } else if (res.data[0].ins_standardMasterIds) {
          var splitStData = res.data[0].ins_standardMasterIds.split(",");
          var combineArray = [];
          splitStData.forEach(function (element) {
            var splitByCol = element.split(":")[0];
            combineArray.push(splitByCol);
          });
          console.log(combineArray.toString());
          axios
            .get(url + `standards?_where=(id,in,${combineArray.toString()})`)
            .then((res) => {
              setStandardMasterArray(res.data);
              console.log("standards", res.data);
            });
        }
      })
      .catch((err) => {
        console.log("certificate data fetching error: ", err);
      });

    axios
      .get(
        url +
          `xjoin?_join=cer.certificates,_j,d.datasheets&_on1=(cer.id,eq,d.id)&_fields=cer.approvedby,d.requestedname,d.calibratedby,d.revisionNumber,d.calibrationDate,d.amendment,d.calFrequency&_where=(cer.id,eq, ${certificateId})`
      )
      .then((res) => {
        setApprovedby(
          res.data[0].cer_approvedby ? res.data[0].cer_approvedby : ""
        );
        setCalibratedby(
          res.data[0].d_calibratedby ? res.data[0].d_calibratedby : ""
        );
        setCalibrationFrequency(res.data[0].d_calFrequency);
        setRevisionNumber(res.data[0].d_revisionNumber);
        setCalibrationDate(res.data[0].d_calibrationDate);
        let tmpSplt = res.data[0].d_amendment
          ? res.data[0].d_amendment.split("\n")
          : [];
        setAmendmentHistory(tmpSplt.filter((_, i) => i === tmpSplt.length - 1));
        setRequestedName(
          res.data[0].d_requestedname ? res.data[0].d_requestedname : ""
        );
      });
  }

  function fetchInstrument(instrumentId) {
    let url = BASE_URL;
    axios
      .get(url + `instruments?_where=(id,eq,${instrumentId})`)
      .then((res) => {
        setInstrument(res.data);
      })
      .catch((err) => {
        console.log("certificate tables data fetching error: ", err);
      });
  }

  const getInstrumentsList = () => {
    let url = BASE_URL;
    axios
      .get(url + "instruments")
      .then((res) => {
        setInstrumentArray(res.data);
      })
      .catch((error) => {
        console.log("Something Went Wrong!");
      });
  };

  const submitCertificate = () => {
    // props.setLoader(true);

    let data = {
      certificateNumber: certificateNumber,
      dcNumber: dcNumber,
      workOrderNumber: workOrderNumber,
      serviceReqNumber: serviceReqNumber,
      ULRNo: ulrNumber,
      poNumber: poNo,
      poDate: poDate
        ? moment(poDate).format("YYYY-MM-DD")
        : moment(new Date()).format("YYYY-MM-DD"),

      dateOfIssue: dateOfIssue
        ? moment(dateOfIssue).format("YYYY-MM-DD")
        : moment(new Date()).format("YYYY-MM-DD"),

      calibrationDate: calibrationDate
        ? moment(calibrationDate).format("YYYY-MM-DD")
        : moment(new Date()).format("YYYY-MM-DD"),

      nextDueDate: nextDueDate ? moment(nextDueDate).format("YYYY-MM-DD") : "",

      receiptDate: receiptDate
        ? moment(receiptDate).format("YYYY-MM-DD")
        : moment(new Date()).format("YYYY-MM-DD"),

      dcDate: dcDate
        ? moment(dcDate).format("YYYY-MM-DD")
        : moment(new Date()).format("YYYY-MM-DD"),
    };

    axios
      .patch(BASE_URL + "certificates/" + certificateId, data)
      .then((res) => {
        toast.success("Datasheet updated successfully!");
        // props.setLoader(false);
      })
      .catch((err) => {
        toast.error("error : " + err);
        // props.setLoader(false);
      });
  };

  useEffect(() => {
    getInstrumentsList();
    fetchCertificate();
    fetchSettings();
  }, []);

  useEffect(() => {
    if (props.isPrint === true) {
      setisPrinting(true);
      setTimeout(() => {
        generatePDF();
      }, 2000);
    }
  }, [props.isPrint]);

  var refresh = () => {
    window.location.reload(false);
  };

  function changeCertificateStatus(ids) {
    // props.setLoader(true);
    let url = BASE_URL;
    if (ids === 3) {
      axios
        .patch(url + `certificates/${id}`, { status: 3, approvedby: user[1] })
        .then((res) => {
          toast.success("Certificates are approved successfully!");
          // props.setLoader(false);
          setTimeout(refresh, 500);
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
    } else {
      axios
        .patch(url + `certificates/${id}`, { status: 4, approvedby: user[1] })
        .then((res) => {
          toast("Certificates are rejected successfully!");
          // props.setLoader(false);
          setTimeout(refresh, 500);
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
    }
  }

  const generateDynamicHeader = async (
    ulrNumber,
    certificateNumber,
    pageNo,
    totalPages
  ) => {
    const formattedCurrentPage = pageNo.toString().padStart(2, "0");
    const formattedTotalPages = totalPages.toString().padStart(2, "0");
    const pageRange = `${formattedCurrentPage}/${formattedTotalPages}`;
    let htmlString = `    
    <div id="responsivepdfheader">
      <table  style="width:100%;">
        <tr>
          ${
            ulrNumber
              ? `<td style="padding:5px;font-family: Calibri, sans-serif;height:60px;">
            <b style="font-size:20px;">Url No : </b> 
            <span style="font-size:19px;">${ulrNumber}</span>
          </td>`
              : ""
          }
          <td style="padding:5px;font-family: Calibri, sans-serif;height:60px;">
            <b style="font-size:20px;">Certificate No : </b>
            <span style="font-size:19px;">${certificateNumber}</span>
          </td>
          <td style="padding:5px;font-family: Calibri, sans-serif;height:60px;">
            <b style="font-size:20px;">Page Number : </b>
            <span style="font-size:19px;">${pageRange}</span>
          </td>
        </tr>
      </table>
    </div>
    `;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 2, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };
  const generateLetterHeadHeader = async () => {
    let htmlString = `
    <div>
      <img src=${UnitechHeader} style="width:100%; height:170px;"/>
    </div>`;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 2, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };
  const generateDynamicFooter = async (
    calibratedby,
    approvedby,
    calibrationDate,
    pageNo,
    totalPages
  ) => {
    const formattedCurrentPage = pageNo.toString().padStart(2, "0");
    const formattedTotalPages = totalPages.toString().padStart(2, "0");
    const pageRange = `${formattedCurrentPage}/${formattedTotalPages}`;
    let htmlString = `
    <div id="responsivepdfheader">
    <table style="width:100%;">
        <tr>
            ${
              electronicSign
                ? `<td style="padding:5px;width:50%;text-align: left;font-family: Calibri, sans-serif;line-height:30px;">
                <b style="font-size:20px;">CALIBRATED BY <br> Electronically signed by </b>
                <br>
                <span style="font-size:19px;">${calibratedby}<br>${moment(
                    calibrationDate
                  ).format("DD/MM/YYYY")}</span>
            </td>`
                : `<td
                style="vertical-align: top;height:100px;padding:5px;width:50%;text-align: left;font-family: Calibri, sans-serif;line-height:30px;">
                <b style="font-size:20px;vertical-align: top;">CALIBRATED BY</b>
                <br>
                <span style="font-size:19px;">${calibratedby}</span>
            </td>`
            }
            </td>
            ${
              electronicSign
                ? `<td style="padding:5px;width:50%;text-align: right;font-family: Calibri, sans-serif;line-height:30px;">
                <b style="font-size:20px;">AUTHORISED SIGNATORY <br> Electronically signed by </b> <br>
                <span style="font-size:19px;">${approvedby}<br>${moment(
                    calibrationDate
                  ).format("DD/MM/YYYY")}</span>`
                : `
            <td
                style="vertical-align: top;height:100px;padding:5px;width:50%;text-align: right;font-family: Calibri, sans-serif;line-height:30px;">
                <b style="font-size:17px;vertical-align: top;">AUTHORISED SIGNATORY </b>
                <br>
                <span style="font-size:19px;">${approvedby}</span>
                `
            }
            </td>
        </tr>
 <tr>
        <td
        style="vertical-align: top;padding:5px;width:50%;text-align: left;font-family: Calibri, sans-serif;line-height:30px;">
        <b style="font-size:17px;vertical-align: top;">
        PTCC/7.8/FF/01	
        </b>
        </td>
        <td
        style="vertical-align: top;padding:5px;width:50%;text-align: right;font-family: Calibri, sans-serif;line-height:30px;">
        <b style="font-size:17px;vertical-align: top;">Page No </b> :
        <span style="font-size:19px;"> ${pageRange}</span>
        </td>
        </tr>
    </table>
</div>`;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 2, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };
  const generateLetterHeadFooter = async () => {
    let htmlString = `
    <div>
      <img src=${UnitechFooter} style="width:100%; height:150px;"/>
    </div>`;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 2, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };
  const generatePDF = async () => {
    const element = printComponentRef.current;
    let pdf = await html2pdf()
      .from(element)
      .set({
        margin: [120, 1, 165, 1],
        filename: `certificate_${certificateId}.pdf`,
        pagebreak: { after: "section", mode: ["css", "legacy"] },
        html2canvas: {
          dpi: 192,
          scale: 4,
          letterRendering: true,
          useCORS: true,
        },
        jsPDF: {
          orientation: "portrait",
          unit: "pt",
          format: "a4",
        },
      })
      .toPdf()
      .get("pdf");

    const totalPages = pdf.internal.getNumberOfPages();
    for (let i = 1; i <= totalPages; i++) {
      pdf.setPage(i);
      pdf.setFontSize(9);
      pdf.setTextColor(0, 0, 0);
      pdf.setFont("Courier");

      let image = await generateDynamicHeader(
        ulrNumber,
        certificateNumber,
        i,
        totalPages
      );
      pdf.addImage(image, 0, 70, 595, 55);  

       image = await generateDynamicFooter(
        calibratedby,
        approvedby,
        calibrationDate,
        i,
        totalPages
      );
      pdf.addImage(image, 0, 668, 595, 100);

      if (letterHead) {
        let image = await generateLetterHeadHeader();

        pdf.addImage(image, 0, 0, 595, 70);

        image = await generateLetterHeadFooter();
        pdf.addImage(image, 0, 790, 595, 50);
      }
      if (electronicSign) {
        const text = 'This certificate is electronically signed and does not required physical signature';
        const textWidth = pdf.getStringUnitWidth(text) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
        const pageWidth = pdf.internal.pageSize.getWidth();
        const x = (pageWidth - textWidth) / 2;
        pdf.text(text, x, 765);
      }
      if (i === totalPages) {
        const text = '--------- END OF CALIBRATION CERTIFICATE ---------';
        const textWidth = pdf.getStringUnitWidth(text) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
        const pageWidth = pdf.internal.pageSize.getWidth();
        const x = (pageWidth - textWidth) / 2;
        pdf.text(text, x, 780);
      }

    }

    pdf.save();
  };

  // print view
  const printView = () => {
    return (
      // <>
      <div
        id="printOnly2"
        // style={{ pageBreakAfter: "always" }}
        ref={printComponentRef}
      >
        <div style={{ width: "100%" }} id="header-section"></div>
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td style={{ border: "none" }}>
                <div
                  class="content"
                  style={{
                    fontSize: "11px",
                    margin: "20px",
                    marginTop: "0px",
                    marginBottom: "0px",
                    padding: "10px",
                    border: "1px solid black",
                  }}
                >
                  <h5 style={{ textAlign: "center", marginBottom: "5px" }}>
                    <b>
                      <u>CALIBRATION CERTIFICATE</u>
                    </b>
                  </h5>
                  <Table>
                    {customerDetailsTable()}
                    <TableRow>
                      <TableCell colSpan={2} align="center">
                        <b>DETAILS OF UNIT UNDER CALIBRATION (UUC)</b>
                      </TableCell>
                    </TableRow>
                    {unitCalibrationDetails()}
                    <Table
                      className={classes.table}
                      style={{ marginBottom: "3px" }}
                    >
                      <TableRow>
                        <TableCell>
                          <b>Environmental Conditions: </b>
                        </TableCell>
                        <TableCell>
                          <span>
                            <b style={{ paddingRight: "10px" }}>
                              Room Temperature :
                            </b>
                            {startTemp} C°
                          </span>
                        </TableCell>
                        <TableCell>
                          <span>
                            <b style={{ paddingRight: "10px" }}>Relative Humidity:</b>
                            {startHumidity} %RH
                          </span>
                        </TableCell>
                      </TableRow>
                    </Table>
                    <Table
                      className={classes.table}
                      style={{ marginBottom: "3px" }}
                    >
                      <TableRow>
                        <TableCell>
                          <b>Calibration Procedure</b>
                        </TableCell>
                        <TableCell>
                          <span>
                            <b style={{ paddingRight: "10px" }}>
                              Work instruction Number :
                            </b>
                            {calibrationProcedureNo} is based on {referenceStandards}
                          </span>
                        </TableCell>
                      </TableRow>
                    </Table>
                    {masterDataTable()}
                  </Table>
                  <div>
                    <div style={{ textAlign: "left" }}>
                      <div style={{ marginBottom: "5px", marginTop: "10px" }}>
                        <b>DISCIPLINE : </b>
                        {disciplineName}
                      </div>
                      <div className="row">
                        <RenderStaticTables
                          tableDisplayStatus={tableDisplayStatus}
                          configuration={configuration}
                          certificateId={certificateId}
                          instrumentId={instrumentId}
                          deletedTableIds={deletedTableIds}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div
                          style={{
                            border: "1px solid black",
                            padding: "5px",
                            flex: "1",
                          }}
                          className="break-remark"
                        >
                          <div className="row">
                            <div className="col-md-6">
                              <div style={{ float: "left" }}>
                                <b
                                  style={{ float: "left", fontSize: "11px" }}
                                  className="standardUnitTable"
                                >
                                  Remarks :
                                </b>
                              </div>
                            </div>
                          </div>
                          <div className="text-start" style={{}}>
                            <pre
                              style={{
                                fontFamily: " Calibri, sans-serif",
                                fontSize: "10px",
                                marginBottom: "0px",
                                lineHeight: 1,
                                whiteSpace: "pre-wrap",
                              }}
                            >
                              {compliance
                                ? `The results ${
                                    complianceStatus == 1 ? "" : "doesn't"
                                  } comply with acceptance criteria
                                  \nReason For Calibration : ${
                                    calibrationReason == 1 ? "New" : "Periodic"
                                  }\n`
                                : ""}
                              {`${
                                configuration?.remark || defaultReamrk
                              }\n${amendmnetHistory}`}
                              {/* {
                                  (settingList["lastModified"] && settingList["lastModified"  ].toLowerCase() === "true") ?
                                    `\nPrint At : ${moment(new Date()).format("mm/DD/yyyy")}` : ""
                                    // `\nLast Modified : ${moment(lastModified).format("mm/DD/yyyy")}` : ""
                                } */}
                            </pre>
                          </div>
                        </div>
                        <div
                          style={{ textAlignLast: "center", padding: "10px" }}
                        >
                          <QRCode
                            value={`${DOMAIN}/#/certificate/print/${certificateId}`}
                            align="center"
                            size={60}
                          />
                          <br/>
                          <b>Static Certificate </b>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div style={{ textAlign: "center" }}>
                    --------- END OF CALIBRATION CERTIFICATE ---------
                  </div>
                  <div style={{ textAlign: "center" }}>
                    {settingList["lastModified"] &&
                    settingList["lastModified"].toLowerCase() === "true"
                      ? `${moment(new Date()).format("DD/MM/yyyy HH:mm:SS")}`
                      : ""}
                  </div> */}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          style={{
            pageBreakBefore: "always",
          }}
        >
          <div
            style={{
              width: "95%",
              padding: "10px",
              margin: "20px auto",
              textAlign: "center"
            }}
          >
            <h4>TERMS & CONDITIONS</h4>
            <div    style={{
              border: "1px solid black",
              margin: "20px auto",
              padding: "10px",
            }}>
            <ol style={{ listStyleType: "decimal", textAlign: "left" }}>
              <li style={{ listStyleType: "decimal",paddingLeft:"20px",textAlign:"justify" }}>
                This Calibration Certificate is issued in accordance with terms
                and conditions of accreditation laid down by National
                Accredatation Board for Testing and Calibration Laboratories
                (NABL), India,Which assesses the Measurement Capability of
                Laboratories and its traceability to recognized National
                Standards and to the Units of Measurement realized at the
                corresponding National Standards Laboratory.
              </li>
              <li style={{ listStyleType: "decimal",paddingLeft:"20px",textAlign:"justify" }}>
                The Calibration masters used are maintained in accordance with
                ISO/IEC 17025:2017 and are traceable to National and
                International Standards.
              </li>
              <li style={{ listStyleType: "decimal",paddingLeft:"20px",textAlign:"justify" }}>
                The reports shall not be reproduced except in full, unless the
                managements representative of{" "}
                <b>Precise Testing and Calibration Centre </b>has given approval
                in writing in accoradance with ISO/lEC 17025 : 2017.
              </li>
              <li style={{ listStyleType: "decimal",paddingLeft:"20px",textAlign:"justify" }}>
                This certificate refers only to the particiular item submitted for calibration.
              </li>
              <li style={{ listStyleType: "decimal",paddingLeft:"20px",textAlign:"justify" }}>
                This Certificate is issued based on customer requirements for
                scientific and industrial purposes and not meant for any legal
                purpose & shall not be produced in the court of law.
              </li>
              <li style={{ listStyleType: "decimal",paddingLeft:"20px",textAlign:"justify" }}>
                UUC(Unit Under Calibration) readings are reported as average of
                3 repeatability readings.
              </li>
              <li style={{ listStyleType: "decimal",paddingLeft:"20px",textAlign:"justify" }}>
                The reported expended uncertainity in measurements is stated as
                the standard uncertainity in measurements multiplied by the
                coverage factor K=2 which is normal distribution corresponds to
                a probability of approximately 95.45%.
              </li>
              <li style={{ listStyleType: "decimal",paddingLeft:"20px",textAlign:"justify" }}>
                Calibration due date mentioned as per customer request.
              </li>
            </ol>
            </div>
          </div>
        </div>
      </div>
      // </>
    );
    function customerDetailsTable() {
      return (
        <Table className={classes.table} style={{ marginBottom: "3px" }}>
          <TableRow className={classes.row}>
            <TableCell className={classes.addressCell} style={{ width: "25%" }}>
              <div>
                <b>Customer Name & Address</b>
              </div>
            </TableCell>
            <TableCell
              style={{
                width: "25%",
                borderLeft: "1px solid black",
                padding: "3px",
              }}
            >
              <div>
                <b>{clientId}</b>
              </div>
              <div>{address}</div>
            </TableCell>
            <TableCell
              style={{
                width: "25%",
                borderLeft: "1px solid black",
                padding: 0,
              }}
            >
              <div style={{ padding: "3px" }}>
                <b>Customer Reference</b>
              </div>
            </TableCell>
            <TableCell
              style={{
                width: "25%",
                borderLeft: "1px solid black",
                padding: 0,
              }}
            >
              <div style={{ padding: "3px" }}>{dcNumber || "\u00A0"}</div>
            </TableCell>
          </TableRow>
        </Table>
      );
    }
    function unitCalibrationDetails() {
      return (
        <Table
          className={classes.table}
          style={{ fontSize: "11px", marginBottom: "3px" }}
        >
          <TableRow className={classes.row}>
            <TableCell className={classes.infoCell} align="center">
              <TableCell
                style={{
                  border: "none",
                  width: "150px",
                  padding: "0px",
                }}
              >
                <b>Name of Instrument </b>
              </TableCell>
              <TableCell style={{ border: "none" }}>:</TableCell>
              <TableCell style={{ border: "none" }}>
                {requestedName.length > 0 ? requestedName : instrumentName}
              </TableCell>
            </TableCell>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px",
                  }}
                >
                  <b>Calibrated At</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>{location}</TableCell>
              </TableRow>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px",
                  }}
                >
                  <b>Make</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>{make}</TableCell>
              </TableRow>
            </TableCell>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px",
                  }}
                >
                  <b>Condition on Receipt</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>{ConOfDuc}</TableCell>
              </TableRow>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px",
                  }}
                >
                  <b>Model</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>{models}</TableCell>
              </TableRow>
            </TableCell>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px",
                  }}
                >
                  <b>Date of Receipt </b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>
                  {moment(receiptDate).format("DD/MM/YYYY")}
                </TableCell>
              </TableRow>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px",
                  }}
                >
                  <b>Serial No</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>{serialNo}</TableCell>
              </TableRow>
            </TableCell>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px",
                  }}
                >
                  <b>Date of Callibration</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>
                  {moment(calibrationDate).format("DD/MM/YYYY")}
                </TableCell>
              </TableRow>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px",
                  }}
                >
                  <b>Range</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>{ranges}</TableCell>
              </TableRow>
            </TableCell>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px",
                  }}
                >
                  <b>Calibration Due On</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>
                  {moment(nextDueDate).format("DD/MM/YYYY")}
                </TableCell>
              </TableRow>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px",
                  }}
                >
                  <b>LC/Resolution</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>
                  {lc
                    ?.replaceAll("||", ", ")
                    ?.replaceAll("|", " to ")
                    ?.replaceAll("#", " ")}
                </TableCell>
              </TableRow>
            </TableCell>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px",
                  }}
                >
                  <b>Date of Issue</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}> {moment(dateOfIssue).format("DD/MM/YYYY")}</TableCell>
              </TableRow>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px",
                  }}
                >
                  <b>Code/ID Number</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>
                  {DUCID}
                </TableCell>
              </TableRow>
            </TableCell>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px",
                  }}
                >
                  <b>Accuracy</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>
                {accuracy
                    ? `± ${accuracy
                        ?.replaceAll("||", ", ")
                        ?.replaceAll("|", " to ")
                        ?.replaceAll("#", " ")}`
                    : ""}
                </TableCell>
              </TableRow>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px",
                  }}
                >
                  <b>Location</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>
                  {locationOfInstrument ? locationOfInstrument : ""}
                </TableCell>
              </TableRow>
            </TableCell>
          </TableRow>
        </Table>
      );
    }
    function masterDataTable() {
      //Please don't put thead or tbody
      return (
        <div align="center">
          <b>Master Equipment used</b>
          <br></br>
          <table
            className="standardUnitTable single-border-table"
            align="center"
          >
            <tr>
              <td>
                <b>SI No</b>
              </td>
              <td>
                <b>Name</b>
              </td>
              <td>
                <b>Make / Model</b>
              </td>
              <td>
                <b>SI. No.</b>
              </td>
              <td>
                <b>Certificate No</b>
              </td>
              <td>
                <b>Validity</b>
              </td>
            </tr>
            {standardMasterArray.map((row, index) => (
              <tr key={row.name}>
                <td>{index + 1}</td>
                <td>{row.standardName}</td>
                <td>
                  {row.make} / {row.model}
                </td>
                <td>{row.serialNo}</td>
                <td>{row.certificateNumber}</td>
                <td>{moment(row.validUpto).format("DD/MM/YYYY")}</td>
              </tr>
            ))}
          </table>
        </div>
      );
    }
  };

  return (
    <>
      <Toolbar style={{ padding: "0px", width: "100%" }}>
        {!props.isPrint && approvedby && !props.certificateIds && (
          <Grid container spacing={2} align="left" style={{ display: "flex" }}>
            <Grid item xs={3}>
              <Autocomplete
                size="small"
                id="combo-box-demo"
                options={[
                  { id: 1, label: "With Letter Head" },
                  { id: 2, label: "Without Letter Head" },
                ]}
                renderInput={(params) => (
                  <TextField {...params} label="letterHead" />
                )}
                onInputChange={(event, newInputValue) => {
                  if (
                    newInputValue === "With Letter Head" ||
                    newInputValue === ""
                  ) {
                    setLetterHead(true);
                  } else if (newInputValue === "Without Letter Head") {
                    setLetterHead(false);
                  }
                  // newInputValue == "Approve" ? setEnableDisable() : setEnableDisable(4+':'+result.toString());
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <Autocomplete
                size="small"
                id="combo-box-demo"
                options={[
                  { id: 1, label: "With Electronically Signed" },
                  { id: 2, label: "Without  Electronically Signed" },
                ]}
                renderInput={(params) => (
                  <TextField {...params} label="Electronically Signed" />
                )}
                onInputChange={(event, newInputValue) => {
                  console.log("newValue : ", newInputValue);
                  if (
                    newInputValue === "With Electronically Signed" ||
                    newInputValue === ""
                  ) {
                    setElectronicSign(true);
                  } else if (
                    newInputValue === "Without  Electronically Signed"
                  ) {
                    setElectronicSign(false);
                  }
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                size="small"
                sx={{ ml: 3 }}
                onClick={() => {
                  generatePDF();
                }}
              >
                Download PDF
              </Button>
            </Grid>
          </Grid>
        )}
      </Toolbar>
      {printView()}
      <Toolbar style={{ padding: "0px", width: "100%" }}>
        {/* {userType != 3 && !isPrinting && (
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            onClick={() => {
              submitCertificate();
            }}
          >
            Save
          </Button>
        )} */}
        {calibratedby &&
          calibratedby.length > 0 &&
          calibratedby !== user[1] &&
          userType != 3 &&
          status !== -1 &&
          status != 3 &&
          !props.isPrint && (
            <Button
              variant="contained"
              color="success"
              size="small"
              sx={{ ml: 3 }}
              onClick={() => {
                changeCertificateStatus(3);
              }}
            >
              Approve
            </Button>
          )}
        {calibratedby &&
          calibratedby.length > 0 &&
          calibratedby !== user[1] &&
          userType != 3 &&
          status !== -1 &&
          status != 3 &&
          status != 4 &&
          !props.isPrint && (
            <Button
              variant="contained"
              color="error"
              size="small"
              sx={{ ml: 3 }}
              onClick={() => {
                changeCertificateStatus(4);
              }}
            >
              Reject
            </Button>
          )}
        {/* {!isPrinting && (
          <Button
            variant="contained"
            size="small"
            sx={{ ml: 3 }}
            onClick={() => {
              setisPrinting(true);
            }}
          >
            Print
          </Button>
        )} */}
      </Toolbar>
    </>
  );
}
